import UserDetailResData from "data/network/res/user/UserDetailResData";
import UserDetailData from "ui/pages/User/UserDetail/UserDetailData";
import UserUpdateData from "ui/pages/User/UserUpdate/UserUpdateData";
import SignUpReqData from "data/network/req/user/SignUpReqData";
import SignUpData from "ui/pages/User/SignUp/SignUpData";

export function resUserDetailToUserDetailData(userInfoResData : UserDetailResData) : UserDetailData {
    const userInfoData : UserDetailData = new UserDetailData();
    userInfoData.userName = userInfoResData.userName;
    userInfoData.userEmail = userInfoResData.userEmail;
    userInfoData.userPosition = userInfoResData.userPosition;
    userInfoData.userStartDate = userInfoResData.userStartDate;
    userInfoData.userStatus = userInfoResData.userStatus;
    userInfoData.totalDayoff = userInfoResData.totalDayoff;
    userInfoData.usedDayoff = userInfoResData.usedDayoff;
    return userInfoData;
}

export function resUserDetailToUserUpdateData(userInfoResData : UserDetailResData) : UserUpdateData {
    const userUpdateData : UserUpdateData = new UserUpdateData();
    userUpdateData.userName = userInfoResData.userName;
    userUpdateData.userEmail = userInfoResData.userEmail;
    return userUpdateData;
}

export function signUpDataToSignUpReqData(signUpData: SignUpData): SignUpReqData {
    const signUpReqData = new SignUpReqData();
    signUpReqData.userEmail = signUpData.userEmail;
    signUpReqData.userName = signUpData.userName;
    signUpReqData.userPassword = signUpData.userPassword;
    signUpReqData.userStartDate = signUpData.userStartDate;
    return signUpReqData;
}