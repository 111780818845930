import styled from '@emotion/styled';

export const PageContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 20px;
`;

export const PageTitle = styled.h2`
    margin-top: 20%;
    margin-bottom: 25px;
`

export const ItemDescriber = styled.div`
    font-size: 14px;
    color: #6B7888;
    margin-bottom: 10px;

`;

export const ItemContainer = styled.div`
    font-size: 15px;
    color: #000000;
    margin-bottom: 25px;
    border: 1px solid #ccc;
    width: calc(100% - 10px);
    height: 40px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    border-radius: 8px;
`;

export const UserDetailUpdateButton = styled.div`
    text-decoration: none;
    color: white;
    background-color: black;
    border-radius: 8px;
    width: 100%;
    height: 50px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
`