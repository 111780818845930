import AdminUserDayoffListReqData from "data/network/req/admin/AdminUserDayoffListReqData";
import AdminDayoffListResData from "data/network/res/admin/AdminDayoffListResData";
import AdminUserDayoffListResData from "data/network/res/admin/AdminUserDayoffListResData";
import AdminUserDetailResData from "data/network/res/admin/AdminUserDetailResData";
import AdminUserListResData from "data/network/res/admin/AdminUserListResData";
import AdminDayoffItemData from "ui/pages/Admin/AdminDayoffList/AdminDayoffItem/AdminDayoffItemData";
import AdminDayoffListData from "ui/pages/Admin/AdminDayoffList/AdminDayoffListData";
import AdminUserDayoffItemData from "ui/pages/Admin/AdminUserDetail/AdminUserDayoffItem/AdminUserDayoffItemData";
import AdminUserDetailData from "ui/pages/Admin/AdminUserDetail/AdminUserDetailData";
import AdminUserItemData from "ui/pages/Admin/AdminUserList/AdminUserItem/AdminUserItemData";
import AdminUserListData from "ui/pages/Admin/AdminUserList/AdminUserListData";
import AdminUserUpdateData from "ui/pages/Admin/AdminUserUpdate/AdminUserUpdateData";

export function resAdminDayoffListToAdminDayoffListData(adminDayoffListResData: AdminDayoffListResData): AdminDayoffListData {
    let adminDayoffListData: AdminDayoffListData = new AdminDayoffListData();
    adminDayoffListResData.adminDayoffList?.forEach((item) => {
        let adminDayoffItemData = new AdminDayoffItemData();
        adminDayoffItemData.dayoffId = item.dayoffId;
        adminDayoffItemData.userName = item.userName;
        adminDayoffItemData.dayoffType = item.dayoffType;
        adminDayoffItemData.totalDayoff = item.totalDayoff;
        adminDayoffItemData.usedDayoff = item.usedDayoff || 0;
        adminDayoffItemData.dayoffStatus = item.dayoffStatus;
        adminDayoffItemData.year = item.year;
        adminDayoffItemData.month = item.month;
        adminDayoffItemData.date = item.date;
        adminDayoffItemData.day = item.day;
        adminDayoffListData.adminDayoffList.push(adminDayoffItemData);
    });
    return adminDayoffListData;
}

export function resAdminUserListToAdminUserListData(adminUserListResData: AdminUserListResData): AdminUserListData {
    let resAdminUserListData = new AdminUserListData();
    adminUserListResData.adminUserList?.forEach((item) => {
        let adminUserItemData = new AdminUserItemData();
        adminUserItemData.userEmail = item.userEmail;
        adminUserItemData.userName = item.userName;
        adminUserItemData.userPosition = item.userPosition;
        adminUserItemData.userStartDate = item.userStartDate;
        adminUserItemData.userEndDate = item.userEndDate;
        adminUserItemData.userStatus = item.userStatus;
        resAdminUserListData.adminUserList.push(adminUserItemData);
    });
    return resAdminUserListData;
}

export function resAdminUserDetailToAdminUserDetailData(adminUserDetailResData: AdminUserDetailResData): AdminUserDetailData {
    let adminUserDetailData = new AdminUserDetailData();
    adminUserDetailData.userEmail = adminUserDetailResData.userEmail;
    adminUserDetailData.userName = adminUserDetailResData.userName;
    adminUserDetailData.userEmail = adminUserDetailResData.userEmail;
    adminUserDetailData.totalDayoff = adminUserDetailResData.totalDayoff;
    adminUserDetailData.usedDayoff = adminUserDetailResData.usedDayoff || 0;
    adminUserDetailData.userPosition = adminUserDetailResData.userPosition;
    adminUserDetailData.userStatus = adminUserDetailResData.userStatus;
    adminUserDetailData.userStartDate = adminUserDetailResData.userStartDate;
    adminUserDetailData.userEndDate = adminUserDetailResData.userEndDate;
    adminUserDetailResData.userDayoffList?.forEach((item) => {
        let adminUserDayoffItemData = new AdminUserDayoffItemData();
        adminUserDayoffItemData.dayoffType = item.dayoffType;
        adminUserDayoffItemData.dayoffStatus = item.dayoffStatus;
        adminUserDayoffItemData.year = item.year;
        adminUserDayoffItemData.month = item.month;
        adminUserDayoffItemData.date = item.date;
        adminUserDayoffItemData.day = item.day;
        adminUserDetailData.userDayoffList.push(adminUserDayoffItemData);
    });
    adminUserDetailData.userDayoffList.sort((a, b) => {
        const aDate = new Date(a.year, a.month - 1, a.date);
        const bDate = new Date(b.year, b.month - 1, b.date);
        return bDate.getTime() - aDate.getTime();
    });
    return adminUserDetailData;
}

export function adminUserDetailToAdminUserUpdateData(adminUserDetailData: AdminUserDetailData): AdminUserUpdateData {
    let adminUserUpdateData = new AdminUserUpdateData();
    adminUserUpdateData.userEmail = adminUserDetailData.userEmail;
    adminUserUpdateData.userName = adminUserDetailData.userName;
    adminUserUpdateData.userEmail = adminUserDetailData.userEmail;
    adminUserUpdateData.totalDayoff = adminUserDetailData.totalDayoff;
    adminUserUpdateData.usedDayoff = adminUserDetailData.usedDayoff;
    adminUserUpdateData.userPosition = adminUserDetailData.userPosition;
    adminUserUpdateData.userStatus = adminUserDetailData.userStatus;
    adminUserUpdateData.userStartDate = adminUserDetailData.userStartDate;
    adminUserUpdateData.userEndDate = adminUserDetailData.userEndDate;
    return adminUserUpdateData;
}

export function adminUserDetailToAdminUserDayoffListReqData(adminUserDetailData: AdminUserDetailData): AdminUserDayoffListReqData {
    let adminUserDayoffListReqData = new AdminUserDayoffListReqData();
    adminUserDayoffListReqData.userEmail = adminUserDetailData.userEmail;
    adminUserDayoffListReqData.startDate = adminUserDetailData.dayoffRangeStartDate.replaceAll(".", "-");
    adminUserDayoffListReqData.endDate = adminUserDetailData.dayoffRangeEndDate.replaceAll(".", "-");
    return adminUserDayoffListReqData;
}

export function resAdminUserDayoffListToAdminUserDayoffList(adminUserDayoffListResData: AdminUserDayoffListResData): Array<AdminUserDayoffItemData> {
    let adminUserDayoffList: Array<AdminUserDayoffItemData> = [];
    adminUserDayoffListResData.adminDayoffList.forEach((item) => {
        let adminUserDayoffItemData = new AdminUserDayoffItemData();
        adminUserDayoffItemData.dayoffType = item.dayoffType;
        adminUserDayoffItemData.dayoffStatus = item.dayoffStatus;
        adminUserDayoffItemData.year = item.year;
        adminUserDayoffItemData.month = item.month;
        adminUserDayoffItemData.date = item.date;
        adminUserDayoffItemData.day = item.day;
        adminUserDayoffList.push(adminUserDayoffItemData);
    });
    adminUserDayoffList.sort((a, b) => {
        const aDate = new Date(a.year, a.month - 1, a.date);
        const bDate = new Date(b.year, b.month - 1, b.date);
        return bDate.getTime() - aDate.getTime();
    });
    return adminUserDayoffList;
}