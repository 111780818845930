import styled from "@emotion/styled";
import useCalendarContext from "./useCalendarContext";
import { useSetRecoilState } from "recoil";
import { dateState } from "atoms/date";
import { useLocation } from 'react-router-dom';

const CalendarBody = () => {
  const weeks = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const { daysInMonth, selectedDate, currentDate } = useCalendarContext();
  const setDate = useSetRecoilState(dateState);
  const userRole = localStorage.getItem("authorized");
  const location = useLocation();

  const handleDateClick = (date: string) => {
    selectedDate.selectDate(date);
    if (userRole === "ADMIN" && location.pathname === "/adminDayoffList") return;
    setDate(new Date(date));
  }

  return (
    <Container>
      <DayWrapper>
        {weeks.map((week, index) => (
          <CalendarItem $isSunday={index === 0} key={week}>
            {week}
          </CalendarItem>
        ))}
      </DayWrapper>
      <DayWrapper>
        {daysInMonth.map((date) => (
          <Day
            onClick={() => handleDateClick(date.date)}
            $isCurrentMonth={currentDate.month === date.month}
            $isSelectedDate={selectedDate.date === date.date}
            $isSunday={date.dayIndexOfWeek === 0}
            className={date.month}
            key={date.date}>
            <span>{date.day}</span>
          </Day>
        ))}
      </DayWrapper>
    </Container>
  );
};

export default CalendarBody;

const Container = styled.div`
  @media (max-width: 800px) {
    width: calc(100% - 30px);
  }
  @media (min-width: 800px) {
    width: 770px;
    margin-left: auto;
    margin-right: auto;
  }
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: white;
  padding: 15px 15px;
  border-bottom: 1px solid #e0e0e0;
`;

const DayWrapper = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: repeat(7, minmax(50px, 1fr));
  grid-row-gap: 10px;
`;
const CalendarItem = styled.div<{ $isSunday: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ $isSunday }) => ($isSunday ? "red" : "black")};
  font-weight: 200;
  font-size: 15px;
`;

const Day = styled.div<{
  $isCurrentMonth?: boolean;
  $isSelectedDate: boolean;
  $isSunday: boolean;
}>`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${({ $isCurrentMonth, $isSelectedDate, $isSunday }) => {
    if ($isSelectedDate) {
      return "blue";
    } else if (!$isCurrentMonth) {
      return "gray";
    } else if ($isSunday) {
      return "red";
    } else {
      return "black";
    }
  }};
  background-color: ${({ $isSelectedDate }) => ($isSelectedDate ? "#EFEFEF" : "transparent")};
  border-radius: 50%;
  font-size: 15px;
  width: 25px;
  height: 25px;
`;