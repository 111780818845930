import AdminUserDayoffItemData from "./AdminUserDayoffItem/AdminUserDayoffItemData";

export default class AdminUserDetailData {
    
    private _userName!: string;   
    private _userEmail!: string;   
    private _totalDayoff!: number;
    private _usedDayoff!: number;
    private _userPosition!: string;
    private _userStatus!: string;
    private _userStartDate!: string;
    private _userEndDate!: string;
    private _userDayoffList: Array<AdminUserDayoffItemData> = new Array<AdminUserDayoffItemData>();
    private _isUserDetailMode: boolean = true;
    private _dayoffRangeOpened: boolean = false;
    private _dayoffRangeStartDate: string = '';
    private _dayoffRangeEndDate: string = '';

    public get userName(): string {
        return this._userName;
    }
    public set userName(value: string) {
        this._userName = value;
    }
    public get userEmail(): string {
        return this._userEmail;
    }
    public set userEmail(value: string) {
        this._userEmail = value;
    }
    public get totalDayoff(): number {
        return this._totalDayoff;
    }
    public set totalDayoff(value: number) {
        this._totalDayoff = value;
    }
    public get usedDayoff(): number {
        return this._usedDayoff;
    }
    public set usedDayoff(value: number) {
        this._usedDayoff = value;
    }
    public get userPosition(): string {
        return this._userPosition;
    }
    public set userPosition(value: string) {
        this._userPosition = value;
    }
    public get userStatus(): string {
        return this._userStatus;
    }
    public set userStatus(value: string) {
        this._userStatus = value;
    }
    public get userStartDate(): string {
        return this._userStartDate;
    }
    public set userStartDate(value: string) {
        this._userStartDate = value;
    }
    public get userEndDate(): string {
        return this._userEndDate;
    }
    public set userEndDate(value: string) {
        this._userEndDate = value;
    }
    public get userDayoffList(): Array<AdminUserDayoffItemData> {
        return this._userDayoffList;
    }
    public set userDayoffList(value: Array<AdminUserDayoffItemData>) {
        this._userDayoffList = value;
    }
    public get isUserDetailMode(): boolean {
        return this._isUserDetailMode;
    }
    public set isUserDetailMode(value: boolean) {
        this._isUserDetailMode = value;
    }
    public get dayoffRangeOpened(): boolean {
        return this._dayoffRangeOpened;
    }
    public set dayoffRangeOpened(value: boolean) {
        this._dayoffRangeOpened = value;
    }
    public get dayoffRangeStartDate(): string {
        return this._dayoffRangeStartDate;
    }
    public set dayoffRangeStartDate(value: string) {
        this._dayoffRangeStartDate = value;
    }
    public get dayoffRangeEndDate(): string {
        return this._dayoffRangeEndDate;
    }
    public set dayoffRangeEndDate(value: string) {
        this._dayoffRangeEndDate = value;
    }
    
}