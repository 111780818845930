import { LayoutContainer, MenuBoard, MenuButtonContainer, Menu, LogoutButton } from "./Layout.styles";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Twirl as MenuButton } from "hamburger-react";
import { useAlertModal } from "hooks/useModal";
import { LoginCodeLabel } from "code/User/LoginCode";

const Layout = () => {
    const userRole = localStorage.getItem("authorized");
    const [isOpened, setIsOpened] = useState(false);
    const navigate = useNavigate();
    const { openAlertModal } = useAlertModal();

    const handleLogout = () => {
        localStorage.removeItem("authorized");
        localStorage.removeItem("refreshToken");
        sessionStorage.removeItem("accessToken");        
        openAlertModal({text: LoginCodeLabel.LOGOUT});
        window.location.href = "/login";
    };

    const handleMenuButtonClick = () => {
        setIsOpened(!isOpened);
    }

    const menuButtonVisible = () => {
        if (window.location.pathname === "/login" || window.location.pathname === "/signup") {
            return false;
        }
        return true;
    }

    const goToPage = (path: string) => {
        navigate(path);
        handleMenuButtonClick();
    }

    return (
        <LayoutContainer>
            <Outlet />           
            {menuButtonVisible() && <MenuButtonContainer>
                <MenuButton toggled={isOpened} toggle={handleMenuButtonClick} color="white" duration={0.3} size={24}/>
                <MenuBoard isOpened={isOpened}>
                    <Menu onClick={() => goToPage("/dashboard")}>
                        <p>대시보드</p>
                    </Menu>
                    {userRole === "ADMIN" ? (
                        <>
                            <Menu onClick={() => goToPage("/adminUserList")}>
                                <p>사용자 관리</p>
                            </Menu>
                            <Menu onClick={() => goToPage("/adminDayoffList")}>
                                <p>연차 관리</p>    
                            </Menu>
                        </>
                    ) : (
                        <>
                            <Menu onClick={() => goToPage("/userInfo")}>
                                <p>내 정보 조회</p>
                            </Menu>
                            <Menu onClick={() => goToPage("/dayoffList")}>
                                <p>내 연차 조회</p>
                            </Menu>
                        </>
                    )}
                    <LogoutButton onClick={handleLogout}>로그아웃</LogoutButton>
                </MenuBoard>
            </MenuButtonContainer>}
        </LayoutContainer>
    );
}

export default Layout;