import styled from '@emotion/styled';
import { DayoffCodeColor } from 'code/Dayoff/DayoffCode';
import { BiChevronDown } from 'react-icons/bi';

export const PageContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    @media (min-width: 800px) {
        width: 800px;
        margin-left: auto;
        margin-right: auto;
    }
    @media (max-width: 800px) {
        width: 100%;
    }
    height: 100%;
    p {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        line-height: 21px; /* 150% */
        white-space: pre-wrap;
    }
`;

interface PageTopContainerProps {
    dateRangeMenuOpened: boolean;
}

export const PageTopContainer = styled.div<PageTopContainerProps>`
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: white;
    @media (min-width: 800px) {
        width: 800px;
    }
    @media (max-width: 800px) {
        width: 100%;
    }
    z-index: 10;
    transition: box-shadow 0s;
    transition-delay: ${props => props.dateRangeMenuOpened ? 0 : 0.3}s;
    box-shadow: ${props => props.dateRangeMenuOpened ? `0px 0px 0px rgba(0, 0, 0, 0)` :  `0px 5px 5px rgba(0, 0, 0, 0.2)`};
`;

export const PageTitle = styled.h2`
    margin-top: 20%;
    margin-bottom: 25px;
    padding-left: 25px;
`;

export const DateRangeContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding-top: 15px;
    padding-left: 25px;
    padding-bottom: 15px;
    color: gray;
    font-size: 14px;
`;

export const DateRangeOpened = styled(BiChevronDown)<PageTopContainerProps>`
    transition: transform 0.5s;
    transform: ${props => props.dateRangeMenuOpened ? 'rotate(180deg)' : 'rotate(0deg)'};
`

interface DateRangeMenuProps {
    dateRangeMenuOpened: boolean;
    topRef: React.RefObject<HTMLDivElement>;
}

export const DateRangeMenu = styled.div<DateRangeMenuProps>`
    overflow: hidden;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    transition: height 0.5s;
    height: ${props => props.dateRangeMenuOpened ? '115px' : '0px'};
    background-color: white;
    top: ${props => props.topRef.current ? props.topRef.current.clientHeight : 0 }px;
    width: calc(100% - 50px);
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
`;

export const DateRangePickerContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: gray;
`;

export const DateRangeButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 25px;
    padding-bottom: 15px;
`;

export const DateRangeSearchButton = styled.div`
    background-color: black;
    color: white;
    border-radius: 8px;
    width: 80px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
`

export const DateRangeResetButton = styled.div`
    background-color: white;
    color: black;
    border-radius: 8px;
    width: 80px;
    height: 35px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
`

export const Seperator = styled.div`
    @media (min-width: 800px) {
        width: 800px;
    }
    @media (max-width: 800px) {
        width: 100%;
    }
    height: 2px;
    background-color: #e9e9e9;
`;

interface DayoffItemProps {
    topRef: React.RefObject<HTMLDivElement>;
}

export const DayoffListContainer = styled.div<DayoffItemProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 15px;
    margin-top: ${props => props.topRef.current ? props.topRef.current.clientHeight + 10 : 10}px;
`;


export const DayoffItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: calc(100% - 50px);
    padding: 15px 25px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
`

export const DayoffItemLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
`

export const DayoffItemRightContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
`

interface DayoffStatusProps {
    status: string;
}

export const DayoffStatus = styled.div<DayoffStatusProps>`
    color: ${(props) => DayoffCodeColor[props.status as keyof typeof DayoffCodeColor]};
`;