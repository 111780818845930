export default class UserUpdateData {
        
    private _userName!: string;
    private _userEmail!: string;
    private _userPassword!: string;
    private _userPasswordConfirm!: string;

    public get userName(): string {
        return this._userName;
    }
    public set userName(value: string) {
        this._userName = value;
    }
    public get userEmail(): string {
        return this._userEmail;
    }
    public set userEmail(value: string) {
        this._userEmail = value;
    }
    public get userPassword(): string {
        return this._userPassword;
    }
    public set userPassword(value: string) {
        this._userPassword = value;
    }
    public get userPasswordConfirm(): string {
        return this._userPasswordConfirm;
    }
    public set userPasswordConfirm(value: string) {
        this._userPasswordConfirm = value;
    }

}