import styled from '@emotion/styled';

export const PageContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 20px;
`;

export const PageTitle = styled.h2`
    margin-top: 20%;
    margin-bottom: 25px;
`

export const FormContainer = styled.div`
    width: 100%;
    height: calc(100% - 20% - 45px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
`;

export const FormItemContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 25px;
    &:last-child {
        margin-bottom: 0;
    }
`;

export const ItemDescriber = styled.div`
    font-size: 14px;
    color: #6B7888;
    margin-bottom: 10px;
    span {
        color: red !important;
    }
`;

interface ItemContainerProps {
    required?: boolean;
    data?: string;
}

export const ItemContainer = styled.input<ItemContainerProps>`
    font-size: 15px;
    color: #000000;
    margin-bottom: 25px;
    outline: none;
    border: 1px solid #ccc;
    width: calc(100% - 10px);
    height: 40px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    transition: border 0.5s;
    &:focus {
        outline: none;
        border: 1px solid black;
    }
    ${(props) => props.required && !props.data && `
        border: 1px solid red;
    `}
`;

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
`;

export const UserDetailUpdateButton = styled.div`
    text-decoration: none;
    color: white;
    background-color: black;
    border-radius: 8px;
    width: 100%;
    height: 50px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
`

export const CancelButton = styled.div`
    text-decoration: none;
    color: black;
    background-color: white;
    border-radius: 8px;
    border: 1px solid black;
    width: 100%;
    height: 50px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
`

