import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SubTitle = styled.h2`
  color: var(--gray-400, #666666);
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
`;

export const HorizontalBar = styled.hr`
  width: 100%;
  border: none;
  height: 1px;
  color: var(--gray-200, #ebebeb); /* old IE */
  background-color: var(--gray-200, #ebebeb); /* Modern Browsers */
  margin: 0;
`;

export const imageWrapperStyle = css`
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Main = styled.main`
  padding: 24px 20px;
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const AddressRowSpaceBetween = styled(RowSpaceBetween)`
  margin-bottom: 10px;
`

export const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;

  button {
    border-radius: 4px;
    flex: 1;
    padding: 10px 0px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  button:last-of-type {
    border: 1px solid var(--gray-300, #ccc);
    color: var(--gray-500, #666);
  }

  button:first-of-type {
    background: var(--green100, #00b140);
    color: var(--white, #fff);
  }
`;

export const MapContainer = styled.div`
  max-width: 1080px;
  min-width: 350px;
  margin-left: auto;
  margin-right: auto;
  height: calc(100vh - 50px);
`;
