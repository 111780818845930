import styled from '@emotion/styled';
import {Pivot as Hamburger} from 'hamburger-react';

export const LayoutContainer = styled.div`
  display: flex;
  @media (max-width: 800px) {
    width: calc(100% - 50px);
    padding-left: 25px;
    padding-right: 25px;
  }

  
  @media (min-width: 800px) {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  position: relative;
`
export interface MenuButtonProps {
  isOpened: boolean;
}
export const MenuButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: fixed;
  @media (max-width: 800px) {
    right: 30px;
    bottom: 30px;
  }
  
  @media (min-width: 800px) {
    right: calc(50% - 370px);
    bottom: 30px;
  }
  background-color: black;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
`

export const MenuBoard = styled.div<MenuButtonProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  padding: 10px 25px;
  position: fixed;
  bottom: 90px;
  @media (max-width: 800px) {
    right: 30px;
  }
  @media (min-width: 800px) {
    right: calc(50% - 370px);
  }
  transition: opacity 0.5s;
  transition-duration: 0.5s;
  opacity: ${(props) => (props.isOpened ? 1 : 0)};
  visibility: ${(props) => (props.isOpened ? 'visible' : 'hidden')};
  overflow: hidden;
  border: 1px solid lightgray;
  border-radius: 8px;
  background-color: white;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
`

export const MenuButton = styled(Hamburger)`
  position: fixed;
  @media (max-width: 800px) {
    right: 30px;
    bottom: 30px;
  }
  @media (min-width: 800px) {
    right: calc(50% - 370px);
    bottom: 30px;
  }
`

export const Menu = styled.div`
  font-size: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  text-decoration: none;
  color: black;
  width: 100%;
  height: 50px;
`

export const LogoutButton = styled.div`
  font-size: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: red;
  width: 100%;
  height: 50px;
  cursor: pointer;
`