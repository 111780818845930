import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class TodayDayoffListRequest extends BaseRequest{
    private readonly URL_DASHBOARD_BASE = `${BaseService.API_URL}/dashboard`;
    private URL_TODAY_DAYOFF_LIST_URL = this.URL_DASHBOARD_BASE+"/todayDayoffList";

    getURL(): string {
        return this.URL_TODAY_DAYOFF_LIST_URL;
    }
}