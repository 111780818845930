import DayoffItemData from "./DayoffItemData";

export default class DayoffListData {

    private _dayoffList: Array<DayoffItemData> = new Array<DayoffItemData>();
    private _dateRangeMenuOpened: boolean = false;
    private _dayoffStartDate: string = "";  
    private _dayoffEndDate: string = "";

    public get dayoffList(): Array<DayoffItemData> {
        return this._dayoffList;
    }
    public set dayoffList(value: Array<DayoffItemData>) {
        this._dayoffList = value;
    }
    public get dateRangeMenuOpened(): boolean {
        return this._dateRangeMenuOpened;
    }
    public set dateRangeMenuOpened(value: boolean) {
        this._dateRangeMenuOpened = value;
    }
    public get dayoffStartDate(): string {
        return this._dayoffStartDate;
    }
    public set dayoffStartDate(value: string) {
        this._dayoffStartDate = value;
    }
    public get dayoffEndDate(): string {
        return this._dayoffEndDate;
    }
    public set dayoffEndDate(value: string) {
        this._dayoffEndDate = value;
    }
}