export enum SignUpCode {
    SUCCESS = "SUCCESS",
    EMAIL_BLANK = "EMAIL_BLANK",
    EMAIL_INVALID_FORMAT = "EMAIL_INVALID_FORMAT",
    NEED_CHECK_EMAIL_DUPLICATED = "NEED_CHECK_EMAIL_DUPLICATED",
    EMAIL_IN_USED = "EMAIL_IN_USED",
    EMAIL_AVAILABLE = "EMAIL_AVAILABLE",
    NAME_BLANK = "NAME_BLANK",
    PASSWORD_BLANK = "PASSWORD_BLANK",
    PASSWORD_CONFIRM_BLANK = "PASSWORD_CONFIRM_BLANK",
    PASSWORD_NOT_MATCH = "PASSWORD_NOT_MATCH",
    ERROR = "ERROR",
    DATA_VALIDATED = "DATA_VALIDATED"
};

export enum SignUpCodeLabel {
    SUCCESS = "회원가입 성공",
    EMAIL_BLANK = "이메일을 입력해주십시오.",
    EMAIL_INVALID_FORMAT = "이메일 형식이 올바르지 않습니다.",
    NEED_CHECK_EMAIL_DUPLICATED = "이메일 중복 확인이 필요합니다.",
    EMAIL_IN_USED = "이미 사용 중인 이메일입니다.",
    EMAIL_AVAILABLE = "사용 가능한 이메일입니다.",
    NAME_BLANK = "이름을 입력해주십시오.",
    PASSWORD_BLANK = "비밀번호를 입력해주십시오.",
    PASSWORD_CONFIRM_BLANK = "비밀번호 확인을 입력해주십시오.",
    PASSWORD_NOT_MATCH = "비밀번호가 일치하지 않습니다.",
    ERROR = "회원가입 중 오류가 발생했습니다.",
    DATA_VALIDATED = "데이터 검증 완료"
};