import styled from '@emotion/styled';
import { ButtonGroup } from 'styles/common.styles';

export const DashBoardContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 10%;
  p {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    line-height: 21px; /* 150% */
    white-space: pre-wrap;
  }
`;

export const DashBoardButtonGroup = styled(ButtonGroup)`
  margin-top: 32px;
`;

export const DayoffContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

export const DayoffTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const DayoffAddButton = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  background-color: black;
  border: none;
  border-radius: 8px;
  padding: 8px 10px;
  cursor: pointer;
`;

export const DayoffItem = styled.div`
  width: calc(100% - 30px);
  height: 60px;
  box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  border-radius: 8px;
`