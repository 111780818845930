import styled from '@emotion/styled';

export const AdminDayoffItemContainer = styled.div`
    width: calc(100% - 50px);
    padding: 15px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border-radius: 8px;
    box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.4);
`;


export const DayoffInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const DayoffButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
`

export const DayoffApprovalButton = styled.div`
    background-color: white;
    color: #3056ff;
    border: none;
    border-radius: 8px;
    border: 1px solid #3056ff;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
`;

export const DayoffRejectButton = styled.div`
    background-color: white;
    color: #ff3056;
    border: none;
    border-radius: 8px;
    border: 1px solid #ff3056;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
`;