import { userDataCode } from "code/Enumerator";
import { isObject } from "common/utils/ObjectUtils";

export default class UserData{
    private static _instance: UserData;
    private constructor() {
    }    
    public static get instance(): UserData {return this._instance || (this._instance = new this());}

    // public gotoHome(): void {
    //     switch(this.appMode) {
    //         case appMode.SELLER:
    //             const sellerMainUrl = "/main";
    //             window.location.href = sellerMainUrl; 
    //             const sellerData = {
    //                 toUrl: sellerMainUrl,
    //             }
    //             break;
    //         case appMode.BUYER:
    //             const buyerMainUrl = "/buyerMain";
    //             window.location.href = buyerMainUrl; 
    //             const buyerData = {
    //                 toUrl: buyerMainUrl,
    //             }
    //             break;
    //         default:
    //             window.location.href = "/";
    //             break;
    //     }
    // };
    // public set appMode(appMode: string) {
    //     if(!appMode) appMode = "";
    //     localStorage.setItem(userDataCode.APP_MODE, isObject(appMode)?JSON.stringify(appMode):appMode);
    // }
    // public get appMode(): string {
    //     const result = localStorage.getItem(userDataCode.APP_MODE) as any; 
    //     return isObject(result)?JSON.parse(result) : result;
    // }
    // public set userEmail(userEmail: string) {
    //     if(!userEmail) userEmail = "";
    //     localStorage.setItem(userDataCode.USER_ID, isObject(userEmail)?JSON.stringify(userEmail):userEmail);
    // }
    // public get userEmail(): string {
    //     const result = localStorage.getItem(userDataCode.USER_ID) as any; 
    //     return isObject(result)?JSON.parse(result) : result;
    // }
    // public set userName(userName: string) {
    //     if(!userName) userName = "";
    //     localStorage.setItem(userDataCode.USER_NAME, isObject(userName)?JSON.stringify(userName):userName);
    // }
    // public get userName(): string {
    //     const result = localStorage.getItem(userDataCode.USER_NAME) as any; 
    //     return isObject(result)?JSON.parse(result) : result;
    // }
    // public set userTel(userTel: string) {
    //     if(!userTel) userTel = "";
    //     localStorage.setItem(userDataCode.USER_TEL, isObject(userTel)?JSON.stringify(userTel):userTel);
    // }
    // public get userTel(): string {
    //     const result = localStorage.getItem(userDataCode.USER_TEL) as any; 
    //     return isObject(result)?JSON.parse(result) : result;
    // }
    // public set snsType(snsType: string) {
    //     if(!snsType) snsType = "";
    //     localStorage.setItem(userDataCode.SNS_TYPE, isObject(snsType)?JSON.stringify(snsType):snsType);
    // }
    // public get snsType(): string {
    //     const result = localStorage.getItem(userDataCode.SNS_TYPE) as any; 
    //     return isObject(result)?JSON.parse(result) : result;
    // }
    // public get snsId(): string {
    //     const result = localStorage.getItem(userDataCode.SNS_ID) as any; 
    //     return isObject(result)?JSON.parse(result) : result;
    // }
    // public set snsId(snsId: string) {
    //     if(!snsId) snsId = "";
    //     localStorage.setItem(userDataCode.SNS_ID, isObject(snsId)?JSON.stringify(snsId):snsId);
    // }
    public set accessToken(accessToken: string) {
        if(!accessToken) accessToken = "";
        localStorage.setItem(userDataCode.ACCESS_TOKEN, isObject(accessToken)?JSON.stringify(accessToken):accessToken);
    }
    public get accessToken(): string {
        const result = localStorage.getItem(userDataCode.ACCESS_TOKEN) as any; 
        return isObject(result)?JSON.parse(result) : result;
    }
    // public logOut(): void {
    //     localStorage.clear();
    //     window.location.href = "/";
    // }    
}