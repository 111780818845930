export default class LoginData {
    private _userEmail: string = "";
    private _userPassword: string = "";
    private _emailInputFocused: boolean = false;
    private _passwordInputFocused: boolean = false;
    private _passwordVisible: boolean = false;

    public get userEmail(): string {
        return this._userEmail;
    }
    public set userEmail(value: string) {
        this._userEmail = value;
    }
    public get userPassword(): string {
        return this._userPassword;
    }
    public set userPassword(value: string) {
        this._userPassword = value;
    }
    public get emailInputFocused(): boolean {
        return this._emailInputFocused;
    }
    public set emailInputFocused(value: boolean) {
        this._emailInputFocused = value;
    }
    public get passwordInputFocused(): boolean {
        return this._passwordInputFocused;
    }
    public set passwordInputFocused(value: boolean) {
        this._passwordInputFocused = value;
    }
    public get passwordVisible(): boolean {
        return this._passwordVisible;
    }
    public set passwordVisible(value: boolean) {
        this._passwordVisible = value;
    }
}