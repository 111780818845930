import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class UserUpdateRequest extends BaseRequest{
    private readonly URL_USER_BASE = `${BaseService.API_URL}/user`;
    private URL_USER_UPDATE_URL = this.URL_USER_BASE+"/update";

    getURL(): string {
        return this.URL_USER_UPDATE_URL;
    }
}