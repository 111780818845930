import SplashReqData from 'data/network/req/user/SplashReqData';
import SplashRequest from 'network/User/SplashRequest';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Splash = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const init = async () => {
            await validateToken();
        }
        init();
        // eslint-disable-next-line
    }, []);

    const validateToken = async () => {
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
            navigate('/login');
            localStorage.removeItem('authorized');
            return;
        }
        const splashReqData = new SplashReqData();
        const splashRequest = new SplashRequest();
        splashReqData.refreshToken = refreshToken;
        const response = await splashRequest.send(splashReqData);
        if (response.responseCode === 401) {
            navigate('/login');
            localStorage.removeItem('authorized');
            return;
        }
        navigate('/dashboard');
    }

    return (
        <div>
            splash
        </div>
    )
}

export default Splash;