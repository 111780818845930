import { useEffect, useState } from "react";
import UserDetailData from "./UserDetailData";
import UserDetailRequest from "network/User/UserDetailRequest";
import UserDetailReqData from "data/network/req/user/UserDetailReqData";
import UserDetailResData from "data/network/res/user/UserDetailResData";
import { resUserDetailToUserDetailData } from "converter/UserConverter";
import { useNavigate } from "react-router-dom";
import { ItemContainer, ItemDescriber, PageContainer, PageTitle, UserDetailUpdateButton } from "./UserDetail.styles";

let userDetailData : UserDetailData = new UserDetailData();
const UserDetail = () => {
    const [viewModel, setViewModel] = useState({userDetailData});
    const userInfoRequest : UserDetailRequest = new UserDetailRequest();
    const navigate = useNavigate();
    useEffect(() => {
        init();
        // eslint-disable-next-line
    }, []);

    const init = async() => {
        await reqUserDetail();
    }

    const reqUserDetail = async() => {
        const userInfoReqData : UserDetailReqData = new UserDetailReqData();
        const userInfoResData = (await userInfoRequest.send(userInfoReqData)) as UserDetailResData;
        userDetailData = resUserDetailToUserDetailData(userInfoResData);
        setViewModel({userDetailData});
    }

    const handleUpdateButtonClick = () => {
        navigate('/userUpdate');
    }

    return (
        <PageContainer>
            <PageTitle>내 정보 조회</PageTitle>
            <ItemDescriber>이름</ItemDescriber>
            <ItemContainer>{viewModel.userDetailData.userName}</ItemContainer>
            <ItemDescriber>이메일</ItemDescriber>
            <ItemContainer>{viewModel.userDetailData.userEmail}</ItemContainer>
            <ItemDescriber>직급</ItemDescriber>
            <ItemContainer>{viewModel.userDetailData.userPosition}</ItemContainer>
            <ItemDescriber>입사일</ItemDescriber>
            <ItemContainer>{viewModel.userDetailData.userStartDate}</ItemContainer>
            <ItemDescriber>상태</ItemDescriber>
            <ItemContainer>{viewModel.userDetailData.userStatus}</ItemContainer>
            <ItemDescriber>총 연차</ItemDescriber>
            <ItemContainer>{viewModel.userDetailData.totalDayoff}일</ItemContainer>
            <ItemDescriber>사용 연차</ItemDescriber>
            <ItemContainer>{viewModel.userDetailData.usedDayoff}일</ItemContainer>
            <ItemDescriber>남은 연차</ItemDescriber>
            <ItemContainer>{viewModel.userDetailData.usableDayoff}일</ItemContainer>
            <UserDetailUpdateButton onClick={handleUpdateButtonClick}>내 정보 수정</UserDetailUpdateButton>
        </PageContainer>
    );
}
export default UserDetail;