import { useEffect, useRef, useState } from "react";
import AdminUserDetailData from "./AdminUserDetailData";
import AdminUserDetailRequest from "network/Admin/AdminUserDetailRequest";
import AdminUserDetailReqData from "data/network/req/admin/AdminUserDetailReqData";
import AdminUserDetailResData from "data/network/res/admin/AdminUserDetailResData";
import { adminUserDetailToAdminUserDayoffListReqData, resAdminUserDayoffListToAdminUserDayoffList, resAdminUserDetailToAdminUserDetailData } from "converter/AdminConverter";
import AdminUserDayoffItem from "./AdminUserDayoffItem/AdminUserDayoffItem";
import { useLocation, useNavigate } from "react-router-dom";
import { ItemContainer, ItemDescriber, PageContainer, PageBodyContainer, PageSlider, PageSliderBorder, PageSliderButton, PageTitle, PageTopContainer, PageBackButton, PageTitleContainer, DayoffListContainer, DayoffRangeButtonContainer, DayoffRangeContainer, DayoffRangeTopContainer, DayoffRangeDateContainer, DayoffRangePickerContainer, DayoffRangeBottomContainer, DayoffRangeSearchButton, DayoffRangeResetButton, UserDetailUpdateButton } from "./AdminUserDetail.styles";
import { BiChevronLeft, BiSliderAlt, BiCalendar, BiCheck, BiRevision } from 'react-icons/bi';
import './AdminUserDetail.css';
import DatePicker from "react-multi-date-picker";
import 'react-multi-date-picker/styles/layouts/mobile.css';
import DatePickerLocale from "common/utils/DatePickerLocale";
import AdminUserDayoffListRequest from "network/Admin/AdminUserDayoffListRequest";
import AdminUserDayoffListResData from "data/network/res/admin/AdminUserDayoffListResData";

let adminUserDetailData : AdminUserDetailData = new AdminUserDetailData();
const AdminUserDetail = () => {
    const location = useLocation();
    const { userEmail } = location.state || {};
    const [viewModel, setViewModel] = useState({adminUserDetailData});
    const adminUserDetailRequest : AdminUserDetailRequest = new AdminUserDetailRequest();
    const adminUserDayoffListRequest: AdminUserDayoffListRequest = new AdminUserDayoffListRequest();
    const navigate = useNavigate();
    const topRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        init();
        // eslint-disable-next-line
    }, []);

    const init = () => {
        reqAdminUserDetail();
    }

    const reqAdminUserDetail = async () => {
        const adminUserDetailReqData = new AdminUserDetailReqData();
        adminUserDetailReqData.userEmail = userEmail;
        const adminUserDetailResData = (await adminUserDetailRequest.send(adminUserDetailReqData)) as AdminUserDetailResData;
        adminUserDetailData = resAdminUserDetailToAdminUserDetailData(adminUserDetailResData);
        setViewModel({adminUserDetailData});
    }

    const reqDayoffList = async () => {
        const adminUserDayoffListReqData = adminUserDetailToAdminUserDayoffListReqData(adminUserDetailData);
        const adminUserDetailResData = (await adminUserDayoffListRequest.send(adminUserDayoffListReqData)) as AdminUserDayoffListResData;
        adminUserDetailData.userDayoffList = resAdminUserDayoffListToAdminUserDayoffList(adminUserDetailResData);
        setViewModel({adminUserDetailData});
    }

    const handleOnUserUpdateBtnClick = () => {
        navigate('/adminUserUpdate', {state: {adminUserDetailData: adminUserDetailData}});
    }



    const handleBackButtonClick = () => {
        navigate(-1);
    }

    const handleClickDetailMode = () => {
        adminUserDetailData.isUserDetailMode = true;
        setViewModel({adminUserDetailData});
    }

    const handleClickDayoffListMode = () => {
        adminUserDetailData.isUserDetailMode = false;
        setViewModel({adminUserDetailData});
    }

    const handleDayoffRangeOpened = () => {
        adminUserDetailData.dayoffRangeOpened = !adminUserDetailData.dayoffRangeOpened;
        setViewModel({adminUserDetailData});
    }

    const handleDayoffRangeStartDateChange = (value: any) => {
        adminUserDetailData.dayoffRangeStartDate = value.format("YYYY.MM.DD");
        setViewModel({adminUserDetailData});
    }

    const handleDayoffRangeEndDateChange = (value:any) => {
        adminUserDetailData.dayoffRangeEndDate = value.format("YYYY.MM.DD");
        setViewModel({adminUserDetailData});
    }

    const handleDayoffRangeSearchClick = async() => {
        adminUserDetailData.dayoffRangeOpened = false;
        setViewModel({adminUserDetailData});
        reqDayoffList();
    }

    const handleDayoffRangeResetClick = () => {
        adminUserDetailData.dayoffRangeStartDate = "";
        adminUserDetailData.dayoffRangeEndDate = "";
        adminUserDetailData.dayoffRangeOpened = false;
        setViewModel({adminUserDetailData});
        reqDayoffList();
    }

    const getDateRangeString = () => {
        if (adminUserDetailData.dayoffRangeStartDate === '' || adminUserDetailData.dayoffRangeEndDate === '') {
            return '전체';
        }
        return `${adminUserDetailData.dayoffRangeStartDate} ~ ${adminUserDetailData.dayoffRangeEndDate}`;
    }

    const UserDetailContainer = () => {
        return (
            <>
                <ItemDescriber>이름</ItemDescriber>
                <ItemContainer>{viewModel.adminUserDetailData.userName}</ItemContainer>
                <ItemDescriber>이메일</ItemDescriber>
                <ItemContainer>{viewModel.adminUserDetailData.userEmail}</ItemContainer>
                <ItemDescriber>남은 연차</ItemDescriber>
                <ItemContainer>{(viewModel.adminUserDetailData.totalDayoff - viewModel.adminUserDetailData.usedDayoff).toString()}</ItemContainer>
                <ItemDescriber>직급</ItemDescriber>
                <ItemContainer>{viewModel.adminUserDetailData.userPosition}</ItemContainer>     
                <ItemDescriber>입사일</ItemDescriber>
                <ItemContainer>{viewModel.adminUserDetailData.userStartDate}</ItemContainer>
                <ItemDescriber>퇴사일</ItemDescriber>
                <ItemContainer>{viewModel.adminUserDetailData.userEndDate || "미정"}</ItemContainer>
                <ItemDescriber>상태</ItemDescriber>
                <ItemContainer>{viewModel.adminUserDetailData.userStatus}</ItemContainer>
                <UserDetailUpdateButton onClick={handleOnUserUpdateBtnClick}>수정하기</UserDetailUpdateButton>
            </>
        )
    }

    const UserDayoffListContainer = () => {
        return (
            <DayoffListContainer>
                <DayoffRangeContainer isOpened={viewModel.adminUserDetailData.dayoffRangeOpened} topRef={topRef}>
                    <DayoffRangeTopContainer>
                        <DayoffRangeDateContainer>
                            <BiCalendar size={24} color="gray" />
                            {getDateRangeString()}
                        </DayoffRangeDateContainer>
                    <DayoffRangeButtonContainer onClick={handleDayoffRangeOpened} >
                        <BiSliderAlt /> 기간 설정
                    </DayoffRangeButtonContainer>
                </DayoffRangeTopContainer>
                <DayoffRangePickerContainer>
                    <DatePicker
                        inputClass="custom-date-picker-detail"
                        value={viewModel.adminUserDetailData.dayoffRangeStartDate}
                        placeholder="시작일"
                        onChange={handleDayoffRangeStartDateChange}
                        locale={DatePickerLocale}
                        format={"YYYY.MM.DD"}
                        formatYear={(year) => `${year}년`}
                        formatMonth={(month) => `${month}`}
                        headerOrder={["YEAR_MONTH", "LEFT_BUTTON", "RIGHT_BUTTON"]}
                        monthYearSeparator=" "
                        className="rmdp-mobile"
                    />
                    <p>~</p>
                    <DatePicker
                        inputClass="custom-date-picker-detail"
                        value={viewModel.adminUserDetailData.dayoffRangeEndDate}
                        placeholder="종료일"
                        onChange={handleDayoffRangeEndDateChange}
                        locale={DatePickerLocale}
                        format={"YYYY.MM.DD"}
                        formatYear={(year) => `${year}년`}
                        formatMonth={(month) => `${month}`}
                        headerOrder={["YEAR_MONTH", "LEFT_BUTTON", "RIGHT_BUTTON"]}
                        monthYearSeparator=" "
                        className="rmdp-mobile"
                        minDate={viewModel.adminUserDetailData.dayoffRangeStartDate}
                    />
                </DayoffRangePickerContainer>
                <DayoffRangeBottomContainer>
                    <DayoffRangeSearchButton onClick={handleDayoffRangeSearchClick}>
                        <BiCheck size={20} />
                        <p>검색</p>
                    </DayoffRangeSearchButton>
                    <DayoffRangeResetButton onClick={handleDayoffRangeResetClick}>
                        <BiRevision size={16} />
                        <p>초기화</p>
                    </DayoffRangeResetButton>
                </DayoffRangeBottomContainer>
                </DayoffRangeContainer>
                {
                    viewModel.adminUserDetailData.userDayoffList.map((item, index) => {
                        return (
                            <AdminUserDayoffItem key={index} adminUserDayoffItemData={item} />
                        )
                    })
                }
            </DayoffListContainer>
        );
    }


    return (
        <PageContainer>
            <PageTopContainer ref={topRef}>
                <PageTitleContainer>
                    <PageBackButton>
                        <BiChevronLeft onClick={handleBackButtonClick} size={28}/>
                    </PageBackButton>
                    <PageTitle>사용자 상세 정보</PageTitle>
                </PageTitleContainer>
                <PageSlider>
                    <PageSliderButton onClick={handleClickDetailMode}>사용자 정보</PageSliderButton>
                    <PageSliderButton onClick={handleClickDayoffListMode}>연차 내역</PageSliderButton>
                </PageSlider>
                <PageSliderBorder isUserDetailMode={viewModel.adminUserDetailData.isUserDetailMode} />
            </PageTopContainer>
            <PageBodyContainer topRef={topRef}>
                    {viewModel.adminUserDetailData.isUserDetailMode ? UserDetailContainer() : UserDayoffListContainer()}
            </PageBodyContainer>
        </PageContainer>
    );
}
export default AdminUserDetail;