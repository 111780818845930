import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class SplashRequest extends BaseRequest {
    private readonly URL_USER_BASE = `${BaseService.API_URL}/user`;
    private URL_SPLASH_URL = this.URL_USER_BASE + "/splash";

    getURL(): string {
        return this.URL_SPLASH_URL;
    }
    
}