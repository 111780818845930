import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const PageContainer = styled.div`
    width: calc(100vw - 50px);
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-left: 25px;
    padding-right: 25px;
`

export const PageTitle = styled.div`
    font-size: 2em;
    font-weight: bold;
    margin-top: calc(40%);
    margin-bottom: calc(20%);
`;

interface InputData {
    focused?: boolean;
    length?: number;
}

export const InputContainer = styled.div<InputData>`
    position: relative;
    transition: border 0.5s;
    ${props => props.focused ? 'border: 1px solid #000000' : 'border: 1px solid #d3d3d3'};
    border-radius: 8px;
    height: 40px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
`;

export const InputDescriber = styled.div<InputData>`
    transition: 
        font-size 0.5s,
        padding-top 0.5s;
    ${props => props.focused || (props.length && props.length > 0) ? 'font-size: 0.75em' : 'font-size: 1em'};
    ${props => props.focused || (props.length && props.length > 0) ? 'padding-top: 0' : 'padding-top: 10px'};
    color: #8391A1;
`

export const Input = styled.input<InputData>`
    border: none;
    background-color: transparent;
    transition: 
        height 0.1s;
    ${props => props.focused || (props.length && props.length > 0) ? 'height: 20px' : 'height: 1px'};
    font-size: 1.1em;
    outline: none;
`;

export const InputIconContainer = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SignUpButton = styled.div`
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 8px;
    width: 100%;
    height: 58px;
`;

export const CancelButton = styled.div`
    border: 1px solid #000000;
    background-color: ##ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    border-radius: 8px;
    width: 100%;
    height: 58px;
    margin-top: 10px;
`;

export const datePicker = css({
    width: '200px',
    height: '60px',
    backgroundColor: 'transparent'
});