import AdminDayoffItemData from "./AdminDayoffItem/AdminDayoffItemData";

export default class AdminDayoffListData {
    
    private _adminDayoffList: Array<AdminDayoffItemData> = new Array<AdminDayoffItemData>();
    private _adminDayoffDateList: Array<string> = new Array<string>();
    private _adminDayoffGroupList: Array<Array<AdminDayoffItemData>> = new Array<Array<AdminDayoffItemData>>();
    public get adminDayoffList(): Array<AdminDayoffItemData> {
        return this._adminDayoffList;
    }
    public set adminDayoffList(value: Array<AdminDayoffItemData>) {
        this._adminDayoffList = value;
    }
    public get adminDayoffDateList(): Array<string> {
        return this._adminDayoffDateList;
    }
    public set adminDayoffDateList(value: Array<string>) {
        this._adminDayoffDateList = value;
    }
    public get adminDayoffGroupList(): Array<Array<AdminDayoffItemData>> {
        return this._adminDayoffGroupList;
    }
    public set adminDayoffGroupList(value: Array<Array<AdminDayoffItemData>>) {
        this._adminDayoffGroupList = value;
    }
    
}