import { useCallback} from "react";
import { useSetRecoilState } from "recoil";
import { modalState } from "atoms/modal";
import { LoadableComponent } from "@loadable/component";
import { CommonModalProps } from "ui/components/Modals/Modals.types";
import loadable from "@loadable/component";
import { DayoffRegistrationModalProps } from "ui/components/Modals/DayoffRegistrationModal/DayoffRegistrationModal";
import { AlertModalProps } from "ui/components/Modals/AlertModal/AlertModal";
import { ForgotPasswordModalProps } from "ui/components/Modals/ForgotPasswordModal/ForgotPasswordModal";

const AlertModal = loadable(
  () => import('ui/components/Modals/AlertModal/AlertModal')
);

const DayoffRegistrationModal = loadable(
  () => import('ui/components/Modals/DayoffRegistrationModal/DayoffRegistrationModal')
);

const ForgotPasswordModal = loadable(
  () => import('ui/components/Modals/ForgotPasswordModal/ForgotPasswordModal')
);

export const useModal = () => {
  const setModal = useSetRecoilState(modalState);

  const openModal = useCallback(
    <T extends CommonModalProps>(
      Component: LoadableComponent<T>,
      props?: Omit<T, 'onClose'>
    ) => {
      setModal((modals) => [...modals, { Component, props }]);
    },
    [setModal]
  );

  const closeModal = useCallback(
    <T extends CommonModalProps>(Component: LoadableComponent<T>) => {
      setModal((modals) => {
        return modals.filter((modal) => modal.Component !== Component);
      });
    },
    [setModal]
  );

  return { openModal, closeModal };
};

export const useAlertModal = () => {
  const { openModal, closeModal } = useModal();

  const openAlertModal = useCallback(
    (props: Omit<AlertModalProps, 'onClose'>) => {
      openModal(AlertModal, props);
    },
    [openModal]
  );

  const closeAlertModal = useCallback(() => {
    closeModal(AlertModal);
  }, [closeModal]);

  return { openAlertModal, closeAlertModal };
};

export const useDayoffRegistrationModal = () => {
  const { openModal, closeModal } = useModal();

  const openDayoffRegistrationModal = useCallback(
    (props: Omit<DayoffRegistrationModalProps, 'onClose'>) => {
        openModal(DayoffRegistrationModal, props);
    }, 
    [openModal]
  );

  const closeDayoffRegistrationModal = useCallback(() => {
    closeModal(DayoffRegistrationModal);
  }, [closeModal]);

  return { openDayoffRegistrationModal, closeDayoffRegistrationModal };
};

export const useForgotPasswordModal = () => {
  const { openModal, closeModal } = useModal();

  const openForgotPasswordModal = useCallback(
    (props: Omit<ForgotPasswordModalProps, 'onClose'>) => {
        openModal(ForgotPasswordModal, props);
    }, 
    [openModal]
  );

  const closeForgotPasswordModal = useCallback(() => {
    closeModal(ForgotPasswordModal);
  }, [closeModal]);

  return { openForgotPasswordModal, closeForgotPasswordModal };
};