export default class AdminUserItemData {
    
    private _userEmail!: string;
    private _userName!: string;
    private _userPosition!: string;
    private _userStartDate!: string;
    private _userEndDate!: string;
    private _userStatus!: string;

    public get userEmail(): string {
        return this._userEmail;
    }
    public set userEmail(value: string) {
        this._userEmail = value;
    }
    public get userName(): string {
        return this._userName;
    }
    public set userName(value: string) {
        this._userName = value;
    }
    public get userPosition(): string {
        return this._userPosition;
    }
    public set userPosition(value: string) {
        this._userPosition = value;
    }
    public get userStartDate(): string {
        return this._userStartDate;
    }
    public set userStartDate(value: string) {
        this._userStartDate = value;
    }
    public get userEndDate(): string {
        return this._userEndDate;
    }
    public set userEndDate(value: string) {
        this._userEndDate = value;
    }
    public get userStatus(): string {
        return this._userStatus;
    }
    public set userStatus(value: string) {
        this._userStatus = value;
    }
    
}