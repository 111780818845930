import { useEffect, useState, useRef } from "react";
import DayoffListData from "./DayoffListData";
import DayoffListRequest from "network/Dayoff/DayoffListRequest";
import DayoffListReqData from "data/network/req/dayoff/DayoffListReqData";
import DayoffListResData from "data/network/res/dayoff/DayoffListResData";
import { dayoffListDataToReqDayoffListData, resDayoffListToDayoffListData } from "converter/DayoffConverter";
import { DateRangeContainer, DayoffItemContainer, DayoffListContainer, PageContainer, PageTopContainer, PageTitle, Seperator, DayoffItemLeftContainer, DayoffItemRightContainer, DayoffStatus, DateRangeOpened, DateRangeMenu, DateRangePickerContainer, DateRangeButtonContainer, DateRangeSearchButton, DateRangeResetButton } from "./DayoffList.styles";
import { DayoffCodeLabel } from "code/Dayoff/DayoffCode";
import { BiCalendar } from 'react-icons/bi';
import DatePicker from "react-multi-date-picker";
import './DayoffList.css';
import "react-multi-date-picker/styles/layouts/mobile.css"
import DatePickerLocale from "common/utils/DatePickerLocale";
import { BiCheck, BiRevision } from 'react-icons/bi';

let dayoffListData : DayoffListData = new DayoffListData();
const DayoffList = () => {
    const [viewModel, setViewModel] = useState({dayoffListData});
    const dayoffListRequest : DayoffListRequest = new DayoffListRequest();
    const topContainerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        init();
        // eslint-disable-next-line
    }, []);

    const init = async() => {
        await reqDayoffList();
    };

    const reqDayoffList = async () => {
        const dayoffListReqData : DayoffListReqData = dayoffListDataToReqDayoffListData(dayoffListData);
        const dayoffListResData = (await dayoffListRequest.send(dayoffListReqData)) as DayoffListResData;
        dayoffListData.dayoffList = resDayoffListToDayoffListData(dayoffListResData);
        dayoffListData.dayoffList.sort((a, b) => {
            const aDate = new Date(a.year, a.month - 1, a.date);
            const bDate = new Date(b.year, b.month - 1, b.date);
            return bDate.getTime() - aDate.getTime();
        })
        setViewModel({dayoffListData});            
    };

    const handleDateRangeClick = () => {
        dayoffListData.dateRangeMenuOpened = !dayoffListData.dateRangeMenuOpened;
        setViewModel({dayoffListData});
    }

    const handleStartDateChange = (value: any) => {
        dayoffListData.dayoffStartDate = value.format("YYYY.MM.DD");
        setViewModel({dayoffListData});
    }

    const handleEndDateChange = (value:any) => {
        dayoffListData.dayoffEndDate = value.format("YYYY.MM.DD");
        setViewModel({dayoffListData});
    }

    const handleDateRangeSearchClick = async() => {
        dayoffListData.dateRangeMenuOpened = false;
        setViewModel({dayoffListData});
        reqDayoffList();
    }

    const handleDateRangeResetClick = () => {
        dayoffListData.dayoffStartDate = "";
        dayoffListData.dayoffEndDate = "";
        dayoffListData.dateRangeMenuOpened = false;
        setViewModel({dayoffListData});
        reqDayoffList();
    }

    const getDateRangeString = () => {
        if (viewModel.dayoffListData.dayoffStartDate === "" || viewModel.dayoffListData.dayoffEndDate === "") {
            return "전체";
        }
        return `${dayoffListData.dayoffStartDate} ~ ${dayoffListData.dayoffEndDate}`;
    }

    const getStatusLabel = (status: string) => {
        return DayoffCodeLabel[status as keyof typeof DayoffCodeLabel];
    }

    const getDayoffType = (type: string) => {
        const dayoffType = type.includes("연차") ? "연차" : "반차";
        const dayoffDetailType = type.includes("연차") ? "연차휴가": type;
        const dayoffCount = type.includes("연차") ? 1 : 0.5;
        return `[${dayoffType}] ${dayoffDetailType} (${dayoffCount}일)`;
    }

    return (
        <PageContainer>
            <PageTopContainer ref={topContainerRef} dateRangeMenuOpened={viewModel.dayoffListData.dateRangeMenuOpened}>
                <PageTitle>내 연차 사용 내역</PageTitle>
                <DateRangeContainer onClick={handleDateRangeClick}>
                    <BiCalendar size={24}/>
                    <div>{getDateRangeString()}</div>
                    <DateRangeOpened size={24} dateRangeMenuOpened={viewModel.dayoffListData.dateRangeMenuOpened}/>
                </DateRangeContainer>
                <Seperator />
            </PageTopContainer>
            <DateRangeMenu
                dateRangeMenuOpened={viewModel.dayoffListData.dateRangeMenuOpened}
                topRef={topContainerRef}>
                <DateRangePickerContainer>
                    <DatePicker
                        inputClass="custom-date-picker"
                        value={viewModel.dayoffListData.dayoffStartDate}
                        placeholder="시작일"
                        onChange={handleStartDateChange}
                        locale={DatePickerLocale}
                        format={"YYYY.MM.DD"}
                        formatYear={(year) => `${year}년`}
                        formatMonth={(month) => `${month}`}
                        headerOrder={["YEAR_MONTH", "LEFT_BUTTON", "RIGHT_BUTTON"]}
                        monthYearSeparator=" "
                        className="rmdp-mobile"
                    />
                    <p>~</p>
                    <DatePicker
                        inputClass="custom-date-picker"
                        value={viewModel.dayoffListData.dayoffEndDate}
                        placeholder="종료일"
                        onChange={handleEndDateChange}
                        locale={DatePickerLocale}
                        format={"YYYY.MM.DD"}
                        formatYear={(year) => `${year}년`}
                        formatMonth={(month) => `${month}`}
                        headerOrder={["YEAR_MONTH", "LEFT_BUTTON", "RIGHT_BUTTON"]}
                        monthYearSeparator=" "
                        className="rmdp-mobile"
                        minDate={viewModel.dayoffListData.dayoffStartDate}
                    />
                </DateRangePickerContainer>
                <DateRangeButtonContainer>
                    <DateRangeSearchButton onClick={handleDateRangeSearchClick}>
                        <BiCheck size={20} />
                        <p>검색</p>
                    </DateRangeSearchButton>
                    <DateRangeResetButton onClick={handleDateRangeResetClick}>
                        <BiRevision size={16} />
                        <p>초기화</p>
                    </DateRangeResetButton>
                </DateRangeButtonContainer>
            </DateRangeMenu>
            <DayoffListContainer topRef={topContainerRef}>
                {viewModel.dayoffListData.dayoffList.length > 0 &&
                    viewModel.dayoffListData.dayoffList.map((item, index) => {
                        return (
                            <DayoffItemContainer key={index}>
                                <DayoffItemLeftContainer>
                                    <div>{item.year}년 {item.month}월 {item.date}일 ({item.day.at(0)})</div>
                                    <div>{getDayoffType(item.dayoffType)}</div>
                                </DayoffItemLeftContainer>
                                <DayoffItemRightContainer>
                                    <DayoffStatus status={item.dayoffStatus}>{getStatusLabel(item.dayoffStatus)}</DayoffStatus>
                                </DayoffItemRightContainer>
                            </DayoffItemContainer>
                        );
                    })
                }
                {viewModel.dayoffListData.dayoffList.length === 0 &&
                    <DayoffItemContainer>사용 내역이 없습니다.</DayoffItemContainer>
                }
            </DayoffListContainer>
        </PageContainer>
    );
}
export default DayoffList;