import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class AdminUserDetailRequest extends BaseRequest{
    private readonly URL_ADMIN_BASE = `${BaseService.API_URL}/admin`;
    private URL_ADMIN_USER_DETAIL_URL = this.URL_ADMIN_BASE+"/userDetail";

    getURL(): string {
        return this.URL_ADMIN_USER_DETAIL_URL;
    }
}