import { LoginCodeLabel } from "code/User/LoginCode";
import { SignUpCodeLabel } from "code/User/SignUpCode";
import LoginData from "ui/pages/User/Login/LoginData";
import SignUpData from "ui/pages/User/SignUp/SignUpData";
import UserUpdateData from "ui/pages/User/UserUpdate/UserUpdateData";

export default class UserValidator {

    static loginValidate(loginReqData: LoginData): string {
        if (loginReqData.userEmail === "") {
            return LoginCodeLabel.EMAIL_BLANK;
        }
        if (loginReqData.userPassword === "") {
            return LoginCodeLabel.PASSWORD_BLANK;
        }
        return LoginCodeLabel.DATA_VALIDATED;
    }

    static signUpValidate(signUpData: SignUpData): string {
        if (signUpData.userEmail === "") {
            return SignUpCodeLabel.EMAIL_BLANK;
        }
        if (!signUpData.emailDuplicationChecked) {
            return SignUpCodeLabel.NEED_CHECK_EMAIL_DUPLICATED;
        }
        if (signUpData.userName === "") {
            return SignUpCodeLabel.NAME_BLANK;
        }
        if (signUpData.userPassword === "") {
            return SignUpCodeLabel.PASSWORD_BLANK;
        }
        if (signUpData.userPasswordConfirm === "") {
            return SignUpCodeLabel.PASSWORD_CONFIRM_BLANK;
        }
        if (signUpData.userPassword !== signUpData.userPasswordConfirm) {
            return SignUpCodeLabel.PASSWORD_NOT_MATCH;
        }
        return SignUpCodeLabel.DATA_VALIDATED;
    }

    static emailFormatValidate(email: string): string {
        const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (email === "") {
            return SignUpCodeLabel.EMAIL_BLANK;
        }
        if (!emailRegExp.test(email)) {
            return SignUpCodeLabel.EMAIL_INVALID_FORMAT;
        }
        return SignUpCodeLabel.DATA_VALIDATED;
    }

    static userUpdateValidate(data: UserUpdateData): string {
        if (!data.userName) {
            return SignUpCodeLabel.NAME_BLANK;
        }
        if (!data.userPassword) {
            return SignUpCodeLabel.PASSWORD_BLANK;
        }
        if (!data.userPasswordConfirm) {
            return SignUpCodeLabel.PASSWORD_CONFIRM_BLANK;
        }
        if (data.userPassword !== data.userPasswordConfirm) {
            return SignUpCodeLabel.PASSWORD_NOT_MATCH;
        }
        return SignUpCodeLabel.DATA_VALIDATED;
    }
}