import BaseReqData from "../BaseReqData";

export default class AdminUserUpdateReqData extends BaseReqData {
  
  public userEmail!: string;
  public userPosition!: string;
  public userStartDate!: string;
  public userEndDate!: string;
  public userStatus!: string;

}