import { useEffect, useRef, useState } from "react";
import AdminUserUpdateData from "./AdminUserUpdateData";
import AdminUserUpdateRequest from "network/Admin/AdminUserUpdateRequest";
import AdminUserUpdateReqData from "data/network/req/admin/AdminUserUpdateReqData";
import { useLocation } from "react-router-dom";
import { UserStatusCode, UserStatusCodeLabel, UserStatusOptions } from "code/User/UserStatusCode";
import { UserPositionCode, UserPositionOptions } from "code/User/UserPositionCode";
import { ButtonContainer, CancelButton, FormContainer, FormItemContainer, InputIconContainer, ItemContainer, ItemDescriber, PageContainer, PageTitle, UserDetailUpdateButton } from "./AdminUserUpdate.styles";
import Select, { SingleValue, StylesConfig } from "react-select";
import DatePicker from "react-multi-date-picker";
import DatePickerLocale from "common/utils/DatePickerLocale";
import "./AdminUserUpdate.css";
import "react-multi-date-picker/styles/layouts/mobile.css";
import { FaCalendarAlt } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

let adminUserUpdateData : AdminUserUpdateData = new AdminUserUpdateData();
const AdminUserUpdate = () => {
    const location = useLocation();
    const { adminUserDetailData } = location.state || {};
    const [viewModel, setViewModel] = useState({adminUserUpdateData});
    const endDateRef = useRef<React.MutableRefObject<any>>(null);
    const adminUserUpdateRequest : AdminUserUpdateRequest = new AdminUserUpdateRequest();
    const navigate = useNavigate();

    useEffect(() => {
        init();
        // eslint-disable-next-line
    }, []);

    const init = () => {
        if (adminUserDetailData.userPosition !== null) {
            adminUserUpdateData.userPosition = adminUserDetailData.userPosition;
        };
        if (adminUserDetailData.userStatus !== null) {
            adminUserUpdateData.userStatus = adminUserDetailData.userStatus;
        };
        if (adminUserUpdateData.userStartDate !== null) {
            adminUserUpdateData.userStartDate = adminUserDetailData.userStartDate;
        } else {
            adminUserUpdateData.userStartDate = new Date().toISOString().slice(0, 10);
        }
        if (adminUserUpdateData.userEndDate !== null) {
            adminUserUpdateData.userEndDate = adminUserDetailData.userEndDate;
        }
        adminUserUpdateData.userEmail = adminUserDetailData._userEmail;
        adminUserUpdateData.userName = adminUserDetailData._userName;
        adminUserUpdateData.userEmail = adminUserDetailData._userEmail;
        adminUserUpdateData.totalDayoff = adminUserDetailData._totalDayoff;
        adminUserUpdateData.usedDayoff = adminUserDetailData._usedDayoff;
        adminUserUpdateData.userPosition = adminUserDetailData._userPosition;
        adminUserUpdateData.userStatus = adminUserDetailData._userStatus;
        adminUserUpdateData.userStartDate = adminUserDetailData._userStartDate;
        adminUserUpdateData.userEndDate = adminUserDetailData._userEndDate;
        setViewModel({adminUserUpdateData});
    }

    const reqAdminUserUpdate = async() => {
        const adminUserUpdateReqData : AdminUserUpdateReqData = new AdminUserUpdateReqData();
        adminUserUpdateReqData.userEmail = viewModel.adminUserUpdateData.userEmail;
        adminUserUpdateReqData.userPosition = viewModel.adminUserUpdateData.userPosition;
        adminUserUpdateReqData.userStartDate = viewModel.adminUserUpdateData.userStartDate;
        adminUserUpdateReqData.userEndDate = viewModel.adminUserUpdateData.userEndDate;
        adminUserUpdateReqData.userStatus = viewModel.adminUserUpdateData.userStatus;
        const response = await adminUserUpdateRequest.send(adminUserUpdateReqData);
        console.log(response);    
    }

    const handleUserPositionChange = (e: SingleValue<{value: UserPositionCode, label: UserPositionCode}>) => {
        adminUserUpdateData.userPosition = e!.value;
        setViewModel({adminUserUpdateData});
    }

    const handleUserStatusChange = (e: SingleValue<{value: UserStatusCode, label: UserStatusCodeLabel}>) => {
        adminUserUpdateData.userStatus = e!.label;
        setViewModel({adminUserUpdateData});
    }

    const handleUserEndDateChange = (value: any) => {
        adminUserUpdateData.userEndDate = value.format('YYYY-MM-DD');
        setViewModel({adminUserUpdateData});
    }

    const handleCancel = () => {
        navigate(-1);
    }

    return (
        <PageContainer>
            <PageTitle>사용자 정보 수정</PageTitle>
            <FormContainer>
                <FormItemContainer>
                    <ItemDescriber>이름</ItemDescriber>
                    <ItemContainer>
                        {viewModel.adminUserUpdateData.userName}
                    </ItemContainer>
                    <ItemDescriber>이메일</ItemDescriber>
                    <ItemContainer>
                        {viewModel.adminUserUpdateData.userEmail}
                    </ItemContainer>
                    <ItemDescriber>남은 연차</ItemDescriber>
                    <ItemContainer>
                        {viewModel.adminUserUpdateData.totalDayoff - viewModel.adminUserUpdateData.usedDayoff || 0}
                    </ItemContainer>
                    <ItemDescriber>직급</ItemDescriber>
                    <Select<{value: UserPositionCode, label: UserPositionCode}>
                        options={UserPositionOptions}
                        value={UserPositionOptions.find(option => option.value === viewModel.adminUserUpdateData.userPosition)}
                        onChange={handleUserPositionChange}
                        styles={selectorStyles}
                    />
                    <ItemDescriber>입사일</ItemDescriber>
                    <ItemContainer>
                        {viewModel.adminUserUpdateData.userStartDate}
                    </ItemContainer>
                    <ItemDescriber>퇴사일</ItemDescriber>
                    <ItemContainer>
                        <DatePicker
                            ref={endDateRef}
                            inputClass="custom-date-picker"
                            value={viewModel.adminUserUpdateData.userEndDate}
                            placeholder="퇴사일"
                            onChange={handleUserEndDateChange}
                            locale={DatePickerLocale}
                            format={"YYYY-MM-DD"}
                            formatYear={(year) => `${year}년`}
                            formatMonth={(month) => `${month}`}
                            headerOrder={["YEAR_MONTH", "LEFT_BUTTON", "RIGHT_BUTTON"]}
                            monthYearSeparator=" "
                            className="rmdp-mobile"
                            containerClassName="custom-date-picker"
                        />
                        <InputIconContainer>
                            <FaCalendarAlt />
                        </InputIconContainer>
                    </ItemContainer>
                    <ItemDescriber>상태</ItemDescriber>
                    <Select<{value: UserStatusCode, label: UserStatusCodeLabel}>
                        options={UserStatusOptions}
                        value={UserStatusOptions.find(option => option.value === viewModel.adminUserUpdateData.userStatus)}
                        onChange={handleUserStatusChange}
                        styles={selectorStyles}
                    />
                </FormItemContainer>
            </FormContainer>
            <ButtonContainer>
                <UserDetailUpdateButton onClick={reqAdminUserUpdate}>수정</UserDetailUpdateButton>
                <CancelButton onClick={handleCancel}>취소</CancelButton>
            </ButtonContainer>
        </PageContainer>
    );
}

const selectorStyles: StylesConfig<any, false> = {
    container: (provided) => ({
        ...provided,
        width: 'calc(100% + 2px)',
        height: '42px',
        marginBottom: '25px',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '1px 10px',
        height: '42px',
        alignItems: 'center',
        borderRadius: '8px',
        '&:hover': {
            cursor: 'pointer',
        },
    }),
    control: (provided, state) => ({
        ...provided,
        transition: 'border 0.3s',
        border: state.isFocused ? '1px solid black' : '1px solid #ccc',
        boxShadow: 'none',
        borderRadius: '8px',
        height: '42px',
        '&:hover': {
            cursor: 'pointer',
        },
    }),
}
export default AdminUserUpdate;