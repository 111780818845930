import { useNavigate } from "react-router-dom";
import AdminUserItemData from "./AdminUserItemData";
import { UserStatusCode, UserStatusCodeLabel } from "code/User/UserStatusCode";
import { UserApprovalButton, UserItemContainer, UserItemLeftContainer, UserItemRightContainer, UserPositionText, UserRejectButton, UserStatusText } from "./AdminUserItem.styles";
import { BiChevronRight } from 'react-icons/bi';

interface AdminUserItemProps {
    adminUserItemData: AdminUserItemData;
    handleApprovalButtonClick: (user: AdminUserItemData) => void;
    handleRejectButtonClick: (user: AdminUserItemData) => void;
}

const AdminUserItem = (props : AdminUserItemProps) => {
    const navigate = useNavigate();

    const handleOnClickUserName = () => {
        navigate(`/adminUserDetail`, {state: {userEmail: props.adminUserItemData.userEmail}});
    }

    const handleApprovalButtonClick = (e: React.MouseEvent) => {
        e.preventDefault();
        props.handleApprovalButtonClick(props.adminUserItemData);
    }

    const handleRejectButtonClick = (e: React.MouseEvent) => {
        e.preventDefault();
        props.handleRejectButtonClick(props.adminUserItemData);
    }

    return (
        <UserItemContainer onClick={handleOnClickUserName}>
            <UserItemLeftContainer>
                <div>
                    {props.adminUserItemData.userName}&nbsp;
                    <UserPositionText>
                        {props.adminUserItemData.userPosition && `(${props.adminUserItemData.userPosition})`}
                    </UserPositionText>
                </div>
                <UserStatusText status={props.adminUserItemData.userStatus}>
                    {UserStatusCodeLabel[props.adminUserItemData.userStatus as keyof typeof UserStatusCodeLabel]}
                </UserStatusText>
            </UserItemLeftContainer>
            <UserItemRightContainer>
                {props.adminUserItemData.userStatus === UserStatusCode.WAIT
                    ? <>
                        <UserApprovalButton onClick={handleApprovalButtonClick}>승인</UserApprovalButton>
                        <UserRejectButton onClick={handleRejectButtonClick}>거절</UserRejectButton>
                    </>
                    : <>
                        <BiChevronRight size={24} color={"gray"}/>
                    </>
                }
            </UserItemRightContainer>
        </UserItemContainer>
    );
}
export default AdminUserItem;