import styled from "@emotion/styled";
import Calendar from "./components/index";

const CalendarComponents = () => {

  return (
    <Container>
      <Calendar>
        <Calendar.Header />
        <Calendar.Body />
        {/* <Calendar.Footer /> */}
      </Calendar>
    </Container>
  );
};

export default CalendarComponents;


const Container = styled.div`
  margin-top: 25px;
  @medai (max-width: 800px) {
    width: 100%;
  }

  @media (min-width: 800px) {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;