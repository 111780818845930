import { useEffect, useState } from "react";
import UserUpdateData from "./UserUpdateData";
import UserUpdateRequest from "network/User/UserUpdateRequest";
import UserUpdateReqData from "data/network/req/user/UserUpdateReqData";
import UserDetailReqData from "data/network/req/user/UserDetailReqData";
import UserDetailRequest from "network/User/UserDetailRequest";
import UserDetailResData from "data/network/res/user/UserDetailResData";
import { resUserDetailToUserUpdateData } from "converter/UserConverter";
import { ButtonContainer, CancelButton, FormContainer, FormItemContainer, ItemContainer, ItemDescriber, PageContainer, PageTitle, UserDetailUpdateButton } from "./UserUpdate.styles";
import UserValidator from "validator/User/UserValidator";

let userUpdateData : UserUpdateData = new UserUpdateData();
const UserUpdate = () => {
    const [viewModel, setViewModel] = useState({userUpdateData});
    const userInfoRequest : UserDetailRequest = new UserDetailRequest();
    const userUpdateRequest : UserUpdateRequest = new UserUpdateRequest();

    useEffect(() => {
        init();
        // eslint-disable-next-line
    }, []);

    const init = async() => {
        await reqUserDetail();
    }

    const reqUserDetail = async() => {
        const userInfoReqData : UserDetailReqData = new UserDetailReqData();
        const userInfoResData = (await userInfoRequest.send(userInfoReqData)) as UserDetailResData;
        userUpdateData = resUserDetailToUserUpdateData(userInfoResData);
        setViewModel({userUpdateData});
    }

    const reqUserUpdate = async() => {
        if (!UserValidator.userUpdateValidate(viewModel.userUpdateData)) {
            return;
        }
        const userUpdateReqData : UserUpdateReqData = new UserUpdateReqData();
        userUpdateReqData.userPassword = viewModel.userUpdateData.userPassword;
        await userUpdateRequest.send(userUpdateReqData);
    }

    const handleUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        userUpdateData.userName = e.target.value;
        setViewModel({userUpdateData});
    }

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        userUpdateData.userPassword = e.target.value;
        setViewModel({userUpdateData});
    }

    const handlePasswordConfirmChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        userUpdateData.userPasswordConfirm = e.target.value;
        setViewModel({userUpdateData});
    }

    const handleUpdateButtonClick = () => {
        reqUserUpdate();
    }

    const handleCancelButtonClick = () => {
        window.history.back();
    }

    return (
        <PageContainer>
            <PageTitle>내 정보 수정</PageTitle>
            <FormContainer>
                <FormItemContainer>
                    <ItemDescriber>이름 <span color="red">*</span></ItemDescriber>
                    <ItemContainer
                        defaultValue={viewModel.userUpdateData.userName}
                        onChange={handleUserNameChange}
                        data={viewModel.userUpdateData.userName}
                        required={true}
                        placeholder="이름은 필수 입력 항목입니다."
                    />
                    <ItemDescriber>이메일</ItemDescriber>
                    <ItemContainer
                        data={viewModel.userUpdateData.userEmail}
                        defaultValue={viewModel.userUpdateData.userEmail}
                        readOnly={true}/>
                    <ItemDescriber>비밀번호 <span color="red">*</span></ItemDescriber>
                    <ItemContainer
                        required={true}
                        data={viewModel.userUpdateData.userPassword || ""}
                        placeholder="비밀번호는 필수 입력 항목입니다."
                        type="password"
                        onChange={handlePasswordChange}/>
                    <ItemDescriber>비밀번호 확인 <span color="red">*</span></ItemDescriber>
                    <ItemContainer
                        required={true}
                        placeholder="비밀번호 확인은 필수 입력 항목입니다."
                        onChange={handlePasswordConfirmChange}
                        type="password"
                        data={viewModel.userUpdateData.userPasswordConfirm || ""}
                    />
                </FormItemContainer>
                <ButtonContainer>
                    <UserDetailUpdateButton onClick={handleUpdateButtonClick}>수정하기</UserDetailUpdateButton>
                    <CancelButton onClick={handleCancelButtonClick}>취소</CancelButton>
                </ButtonContainer>
            </FormContainer>
        </PageContainer>
    );
}
export default UserUpdate;