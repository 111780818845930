export enum DayoffCode {
    WAIT = "WAIT",
    APPROVAL = "APPROVAL",
    REJECTION = "REJECTION",
    CANCEL = "CANCEL"
}

export enum DayoffCodeLabel {
    WAIT = "대기",
    APPROVAL = "승인",
    REJECTION = "거절",
    CANCEL = "취소"
}

export enum DayoffCodeColor {
    WAIT = "green",
    APPROVAL = "blue",
    REJECTION = "red",
    CANCEL = "gray"
}