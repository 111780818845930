import AdminUserItemData from "./AdminUserItem/AdminUserItemData";

export default class AdminUserListData {
    
    private _adminUserList: Array<AdminUserItemData> = new Array<AdminUserItemData>();

    public get adminUserList(): Array<AdminUserItemData> {
        return this._adminUserList;
    }
    public set adminUserList(value: Array<AdminUserItemData>) {
        this._adminUserList = value;
    }
    
}