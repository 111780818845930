import styled from '@emotion/styled';
import { DayoffCodeColor } from 'code/Dayoff/DayoffCode';

export const DayoffItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: calc(100% - 50px);
    padding: 15px 25px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background-color: #fafafa
`


export const DayoffItemLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
`

export const DayoffItemRightContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
`

interface DayoffStatusProps {
    status: string;
}

export const DayoffStatus = styled.div<DayoffStatusProps>`
    color: ${(props) => DayoffCodeColor[props.status as keyof typeof DayoffCodeColor]};
`;