export default class DashBoardData {

    private _todayDayoffList: Array<string> = [];   
    private _selectedDate: Date = new Date();

    public get selectedDate(): Date {
        return this._selectedDate;
    }
    public set selectedDate(value: Date) {
        this._selectedDate = value;
    }
    public get todayDayoffList(): Array<string> {
        return this._todayDayoffList;
    }
    public set todayDayoffList(value: Array<string>) {
        this._todayDayoffList= value;
    }
    
}