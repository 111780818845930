import { useState, useEffect, useRef } from 'react';
import LoginRequest from 'network/User/LoginRequest';
import LoginData from './LoginData';
import LoginReqData from 'data/network/req/user/LoginReqData';
import LoginResData from 'data/network/res/user/LoginResData';
import { LoginCode, LoginCodeLabel } from 'code/User/LoginCode';
import { ForgotPasswordButton, Input, InputContainer, InputDescriber, InputIconContainer, LoginButton, PageContainer, PageTitle, SignupButton } from './Login.styles';
import {HiEye, HiOutlineEyeOff} from 'react-icons/hi';
import { useSetRecoilState } from 'recoil';
import { userRoleState } from 'atoms/layout';
import { useNavigate } from 'react-router-dom';
import { useAlertModal, useForgotPasswordModal } from 'hooks/useModal';
import { ForgotPasswordModalProps } from 'ui/components/Modals/ForgotPasswordModal/ForgotPasswordModal';
import UserValidator from 'validator/User/UserValidator';

const loginData: LoginData = new LoginData();
const Login = () => {
    const [viewModel, setViewModel] = useState({loginData});
    const setUserRole = useSetRecoilState(userRoleState);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const passwordInputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const {openForgotPasswordModal} = useForgotPasswordModal();
    const {openAlertModal} = useAlertModal()

    useEffect(() => {
        init();
        // eslint-disable-next-line
    }, []);

    const init = () => {
        setViewModel({loginData});
    };

    const reqLogin = async () => {
        const loginValidation = UserValidator.loginValidate(loginData);
        if (loginValidation !== LoginCodeLabel.DATA_VALIDATED) {
            openAlertModal({text: loginValidation});
            return;
        }   
        const loginRequest = new LoginRequest();
        const loginReqData = new LoginReqData();
        loginReqData.userEmail = viewModel.loginData.userEmail;
        loginReqData.userPassword = viewModel.loginData.userPassword;
        const response = await loginRequest.send(loginReqData) as LoginResData;
        handleLoginResult(response);
    };

    const changeUserEmail = (value: string) => {
        viewModel.loginData.userEmail = value;
        setViewModel(viewModel);
    };

    const changeUserPassword = (value: string) => {
        viewModel.loginData.userPassword = value;
        setViewModel(viewModel);
    };

    const handleLogin = () => {
        reqLogin();
    }

    const handleSignup = () => {
        navigate('/signup');
    }

    const handleForgotPassword = () => {
        const modalProps: Omit<ForgotPasswordModalProps, "onClose"> = {
        };
        openForgotPasswordModal(modalProps);
    }

    const handleLoginResult = (res: LoginResData) => {
        switch (res.responseMessage) {
            case LoginCode.SUCCESS:
                setUserRole(res.userRole!);
                localStorage.setItem("authorized", res.userRole || "");
                localStorage.setItem("refreshToken", res.refreshToken || "");
                sessionStorage.setItem("accessToken", res.accessToken || "");
                navigate('/dashboard');
                break;
            case LoginCode.EMAIL_NOT_EXIST:
                openAlertModal({text: LoginCodeLabel.EMAIL_NOT_EXIST});
                window.location.reload();
                break;
            case LoginCode.PASSWORD_NOT_CORRECT:
                openAlertModal({text: LoginCodeLabel.PASSWORD_NOT_CORRECT});
                viewModel.loginData.userPassword = '';
                setViewModel({loginData: viewModel.loginData});
                break;
            case LoginCode.LOGIN_LOCKED:
            case LoginCode.WAITING_APPROVAL:
            case LoginCode.WITHDRAWN_USER:
                openAlertModal({text: LoginCodeLabel[res.responseMessage]});
                break;
            default:
                openAlertModal({text: LoginCodeLabel.ERROR});
                break;
        }
    }

    const handleEmailInputFocus = (value: boolean) => {
        viewModel.loginData.emailInputFocused = value;
        setViewModel({loginData: viewModel.loginData});
    }

    const handlePasswordInputFocus = (value: boolean) => {
        viewModel.loginData.passwordInputFocused = value;
        setViewModel({loginData: viewModel.loginData});
    }

    const handlePasswordVisible = () => {
        viewModel.loginData.passwordVisible = !viewModel.loginData.passwordVisible;
        passwordInputRef.current?.setAttribute('type', viewModel.loginData.passwordVisible ? 'text' : 'password');
        setViewModel({loginData: viewModel.loginData});
    }

    const handleInputFocus = (ref: React.RefObject<HTMLInputElement>) => {
        if (ref.current) {
            ref.current.focus();
        }
    }

    return (
        <PageContainer>
            <PageTitle>
                몽키소프트<br/>연차 관리 시스템
            </PageTitle>
            <InputContainer onClick={() => handleInputFocus(emailInputRef)} length={viewModel.loginData.userEmail.length} focused={viewModel.loginData.emailInputFocused}>
                <InputDescriber length={viewModel.loginData.userEmail.length} focused={viewModel.loginData.emailInputFocused}>이메일</InputDescriber>
                <Input 
                    ref={emailInputRef}
                    focused={viewModel.loginData.emailInputFocused}
                    length={viewModel.loginData.userEmail.length}
                    onChange={(e: any) => changeUserEmail(e.target.value)}
                    onFocus={() => handleEmailInputFocus(true)}
                    onBlur={() => handleEmailInputFocus(false)}
                />
            </InputContainer>
            <InputContainer onClick={() => handleInputFocus(passwordInputRef)} length={viewModel.loginData.userPassword.length} focused={viewModel.loginData.passwordInputFocused}>
                <InputDescriber length={viewModel.loginData.userPassword.length} focused={viewModel.loginData.passwordInputFocused}>비밀번호</InputDescriber>
                <Input
                    type="password"
                    ref={passwordInputRef}
                    focused={viewModel.loginData.passwordInputFocused}
                    length={viewModel.loginData.userPassword.length}
                    onChange={(e: any) => changeUserPassword(e.target.value)}
                    onFocus={() => handlePasswordInputFocus(true)}
                    onBlur={() => handlePasswordInputFocus(false)} 
                />
                <InputIconContainer onClick={handlePasswordVisible}>
                    {
                        viewModel.loginData.passwordVisible ? <HiEye /> : <HiOutlineEyeOff />
                    }
                </InputIconContainer>
            </InputContainer>
            <LoginButton onClick={handleLogin}>로그인</LoginButton>
            <SignupButton onClick={handleSignup}>회원가입</SignupButton>
            <ForgotPasswordButton onClick={handleForgotPassword}>비밀번호 찾기</ForgotPasswordButton>
        </PageContainer>
    );
}
export default Login;