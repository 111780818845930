import { AdminDayoffItemContainer, DayoffApprovalButton, DayoffButtonContainer, DayoffInfoContainer, DayoffRejectButton } from "./AdminDayoffItem.styles";
import AdminDayoffItemData from "./AdminDayoffItemData";

interface AdminDayoffItemProps {
    adminDayoffItemData: AdminDayoffItemData;
    reqApproveDayoff: (dayoffId: string, dayoffStatus: string) => void;
}
const AdminDayoffItem = (props : AdminDayoffItemProps) => {

    const onClickApproveDayoff = () => {
        props.adminDayoffItemData.dayoffStatus = "APPROVAL";
        props.reqApproveDayoff(props.adminDayoffItemData.dayoffId, props.adminDayoffItemData.dayoffStatus);
    }
    const onClickRejectDayoff = () => {
        props.adminDayoffItemData.dayoffStatus = "REJECTION";
        props.reqApproveDayoff(props.adminDayoffItemData.dayoffId, props.adminDayoffItemData.dayoffStatus);
    }

    return (
        <AdminDayoffItemContainer>
            <DayoffInfoContainer>
                {props.adminDayoffItemData.userName} ({props.adminDayoffItemData.dayoffType})
            </DayoffInfoContainer>
            <DayoffButtonContainer>
                <DayoffApprovalButton onClick={onClickApproveDayoff}>승인</DayoffApprovalButton>
                <DayoffRejectButton onClick={onClickRejectDayoff}>거절</DayoffRejectButton>
            </DayoffButtonContainer>
        </AdminDayoffItemContainer>
    );
}
export default AdminDayoffItem;