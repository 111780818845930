import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class AdminDayoffListRequest extends BaseRequest{
    private readonly URL_ADMIN_BASE = `${BaseService.API_URL}/admin`;
    private URL_ADMIN_DAYOFF_LIST_URL = this.URL_ADMIN_BASE+"/dayoffList";

    getURL(): string {
        return this.URL_ADMIN_DAYOFF_LIST_URL;
    }
}