import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class CheckEmailRequest extends BaseRequest{
    private readonly URL_USER_BASE = `${BaseService.API_URL}/user`;
    private URL_CHECK_EMAIL_URL = this.URL_USER_BASE+"/check-email";

    getURL(): string {
        return this.URL_CHECK_EMAIL_URL;
    }
}