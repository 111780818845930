import styled from "@emotion/styled";
import useCalendarContext from "./useCalendarContext";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { dateState } from "atoms/date";
import { useSetRecoilState } from "recoil";
import { useEffect } from "react";

const CalendarHeader = () => {
  const { dispatch, currentDate, selectedDate } = useCalendarContext();
  const setDate = useSetRecoilState(dateState);

  useEffect(() => {
    const dateString = new Date().getMonth() + 1 === parseInt(currentDate.month) ? new Date().getFullYear() + "-" + currentDate.month + "-" + new Date().getDate().toString().padStart(2, "0") : currentDate.year + "-" + currentDate.month + "-01";
    selectedDate.selectDate(dateString);
    // eslint-disable-next-line
  }, [currentDate.year, currentDate.month]);

  useEffect(() => {
    setDate(new Date(selectedDate.date));
    // eslint-disable-next-line
  }, [selectedDate.date.substring(0, 7)]);

  const getMonth = () => {
    const month = currentDate.month.toString();
    return month.startsWith('0') ? month.substring(1) : month;
  }

  const handlePrevMonth = () => {
    dispatch.handlePrevMonth();
  }

  const handleNextMonth = () => {
    dispatch.handleNextMonth();
  }

  return (
    <Container>
      <Title>
        {currentDate.year}년 {getMonth()}월
      </Title>
      <ButtonContainer>
        <ChangeButton onClick={handlePrevMonth}>
          <BsChevronLeft size={14} fill="gray" stroke="gray"/>
        </ChangeButton>
        <ChangeButton onClick={handleNextMonth}>
            <BsChevronRight size={14} fill="gray" stroke="gray"/>
        </ChangeButton>
      </ButtonContainer>
    </Container>
  );
};

export default CalendarHeader;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 25px;
  border-bottom: 1px solid #e0e0e0;
`;
const Title = styled.div`
  font-size: 14px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

const ChangeButton = styled.div`
  color: gray;
  cursor: pointer;
`;