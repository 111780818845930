import styled from '@emotion/styled';
import { UserStatusCodeColor } from 'code/User/UserStatusCode';

export const UserItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 30px);
    padding: 10px 15px;
    border-radius: 8px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
`;

export const UserItemLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 15px;
`;

export const UserPositionText = styled.span`
    font-size: 12px;
`

interface UserStatusTextprops {
    status: string;
}

export const UserStatusText = styled.span<UserStatusTextprops>`
    font-size: 14px;
    color: ${props => UserStatusCodeColor[props.status as keyof typeof UserStatusCodeColor]};
`;

export const UserItemRightContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
`;

export const UserApprovalButton = styled.button`
    background-color: white;
    color: #3056ff;
    border: none;
    border-radius: 8px;
    border: 1px solid #3056ff;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
`;

export const UserRejectButton = styled.button`
    background-color: white;
    color: #ff3056;
    border: none;
    border-radius: 8px;
    border: 1px solid #ff3056;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
`;