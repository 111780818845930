import { Route, Routes, Navigate } from "react-router-dom";
import Layout from "ui/components/Layout/Layout";
import Login from "ui/pages/User/Login/Login";
import SignUp from "ui/pages/User/SignUp/SignUp";
import DashBoard from "ui/pages/DashBoard/DashBoard";
import AdminUserList from "ui/pages/Admin/AdminUserList/AdminUserList";
import AdminDayoffList from "ui/pages/Admin/AdminDayoffList/AdminDayoffList";
import UserDetail from "ui/pages/User/UserDetail/UserDetail";
import DayoffList from "ui/pages/Dayoff/DayoffList";
import UserUpdate from "ui/pages/User/UserUpdate/UserUpdate";
import AdminUserDetail from "ui/pages/Admin/AdminUserDetail/AdminUserDetail";
import AdminUserUpdate from "ui/pages/Admin/AdminUserUpdate/AdminUserUpdate";
import Splash from "ui/pages/Splash/Splash";


const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Navigate to={"/splash"} />} />
          <Route path="/splash" element={<Splash />} />
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/adminUserList" element={<AdminUserList />} />
          <Route path="/adminUserDetail" element={<AdminUserDetail />} />
          <Route path="/adminUserUpdate" element={<AdminUserUpdate />} />
          <Route path="/adminDayoffList" element={<AdminDayoffList />} />
          <Route path="/userInfo" element={<UserDetail />} />
          <Route path="/userUpdate" element={<UserUpdate />} />
          <Route path="/dayoffList" element={<DayoffList />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
        </Route>
      </Routes>
    </>
  );
};
export default AppRoutes;