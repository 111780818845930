import DayoffListReqData from "data/network/req/dayoff/DayoffListReqData";
import DayoffListResData from "data/network/res/dayoff/DayoffListResData";
import DayoffItemData from "ui/pages/Dayoff/DayoffItemData";
import DayoffListData from "ui/pages/Dayoff/DayoffListData";

export function resDayoffListToDayoffListData(dayoffListResData : DayoffListResData) : Array<DayoffItemData> {
    let dayoffListData : Array<DayoffItemData> = new Array<DayoffItemData>();
    dayoffListResData.dayoffList?.forEach((item) => {
        let dayoffItemData : DayoffItemData = new DayoffItemData();
        dayoffItemData.dayoffType = item.dayoffType;
        dayoffItemData.dayoffStatus = item.dayoffStatus;
        dayoffItemData.year = item.year;
        dayoffItemData.month = item.month;
        dayoffItemData.date = item.date;
        dayoffItemData.day = item.day;
        dayoffListData.push(dayoffItemData);
    });
    return dayoffListData;
}

export function dayoffListDataToReqDayoffListData(dayoffListData: DayoffListData): DayoffListReqData {
    let dayoffListReqData : DayoffListReqData = new DayoffListReqData();
    dayoffListReqData.startDate = dayoffListData.dayoffStartDate.replaceAll(".", "-");
    dayoffListReqData.endDate = dayoffListData.dayoffEndDate.replaceAll(".", "-");
    return dayoffListReqData;
}
