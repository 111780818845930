import styled from '@emotion/styled';


export const PageContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 20px;
    position: relative;
`;

export const PageTopContainer = styled.div`
    position: fixed;
    top: 0;
    @media(min-width: 800px) {
        width: 800px;
        left: calc(50% - 400px);
    }
    @media(max-width: 800px) {
        width: 100%;
        left: 0;
    }
    background-color: white;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-bottom: 1px solid #e5e5e5;
`;

export const PageTitleContainer = styled.div`
    margin-top: 10%;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 25px);
    padding-left: 25px;
`;

export const PageBackButton = styled.div`
    border-radius: 8px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: 25px;
    cursor: pointer;
`

export const PageTitle = styled.div`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
`

export const PageSlider = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
`

export const PageSliderButton = styled.div`
    width: 50%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

interface PageSliderBorderProps {
    isUserDetailMode: boolean;
}

export const PageSliderBorder = styled.div<PageSliderBorderProps>`
    width: 50%;
    height: 2px;
    background-color: black;
    transition: margin-left 0.3s;
    margin-left: ${(props: PageSliderBorderProps) => props.isUserDetailMode ? '0' : '50%'};
`

interface PageDetailProps {
    topRef: React.RefObject<HTMLDivElement>;
}   

export const PageBodyContainer = styled.div`
    width: 100%;
    margin-top: ${(props: PageDetailProps) => props.topRef.current ? props.topRef.current.clientHeight : 0}px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

export const ItemDescriber = styled.div`
    font-size: 14px;
    color: #6B7888;
    margin-bottom: 10px;

`;

export const ItemContainer = styled.div`
    font-size: 15px;
    color: #000000;
    margin-bottom: 25px;
    border: 1px solid #ccc;
    width: calc(100% - 15px);
    height: 40px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    border-radius: 8px;
`;

export const UserDetailUpdateButton = styled.div`
    text-decoration: none;
    color: white;
    background-color: black;
    border-radius: 8px;
    width: 100%;
    height: 50px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin-bottom: 25px;
`



interface DayoffRangeProps {
    isOpened?: boolean;
    topRef?: React.RefObject<HTMLDivElement>;
}

export const DayoffRangeContainer = styled.div<DayoffRangeProps>`
    position: fixed;
    top: ${(props: DayoffRangeProps) => props.topRef?.current ? props.topRef!.current.clientHeight : 0}px;
    @media (min-width: 800px) {
        width: 800px;
        left: calc(50% - 400px);
    }
    @media (max-width: 800px) {
        width: 100%;
        left: 0;
    }
    height: ${(props: DayoffRangeProps) => props.isOpened ? '165px' : '30px'};
    border-bottom-left-radius: ${(props: DayoffRangeProps) => props.isOpened ? '8px' : '0'};
    border-bottom-right-radius: ${(props: DayoffRangeProps) => props.isOpened ? '8px' : '0'};
    box-shadow: ${(props: DayoffRangeProps) => props.isOpened ? '3px 3px 5px rgba(0, 0, 0, 0.3)' : 'none'};
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;
    padding-top: 15px;
    overflow: hidden;
    transition: 
        height 0.3s,
        border-bottom-left-radius 0.3s,
        border-bottom-right-radius 0.3s,
        box-shadow 0.15s;
    transition-delay: 0s, 0s, 0s, 0.15s;
    gap: 10px;
`

export const DayoffRangeTopContainer = styled.div`
    display: flex;
    width: calc(100% - 30px);
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
`

export const DayoffRangeDateContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
`

export const DayoffRangeButtonContainer = styled.div<DayoffRangeProps>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
`

export const DayoffRangePickerContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
`

export const DayoffRangeBottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
`

export const DayoffRangeSearchButton = styled.div`
    background-color: black;
    color: white;
    border-radius: 8px;
    width: 80px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
`

export const DayoffRangeResetButton = styled.div`
    background-color: white;
    color: black;
    border-radius: 8px;
    width: 80px;
    height: 35px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
`

export const DayoffListContainer = styled.div`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
`