import React from "react";

class BaseService extends React.Component {
  private static readonly PROTOCAL_HTTP = 'http://';
  private static readonly PROTOCAL_HTTPS = 'https://';
  // private static readonly BASE_URL_LOCAL = 'localhost:8080'; // 백엔드 로컬 테스트 필요시
  private static readonly BASE_URL_DEV = '192.168.0.115:38080'; // 백엔드 개발 서버 주소
  private static readonly BASE_URL = 'api.dayoff.monkey-soft.com'; // 백엔드 운영 api 주소
  public static readonly API_URL = BaseService.PROTOCAL_HTTPS + BaseService.BASE_URL; // 운영 주소
  public static readonly API_URL_DEV = BaseService.PROTOCAL_HTTP + BaseService.BASE_URL_DEV; // 개발 주소
  // public static readonly API_URL = BaseService.PROTOCAL_HTTP + BaseService.BASE_URL_LOCAL;
}

export default BaseService; //다른 JS파일에서 불러올 수 있도록 내보내주기