import { DayoffCodeLabel } from "code/Dayoff/DayoffCode";
import { DayoffItemLeftContainer, DayoffItemRightContainer, DayoffStatus } from "./AdminUserDayoffItem.styles";
import { DayoffItemContainer } from "./AdminUserDayoffItem.styles";
import AdminUserDayoffItemData from "./AdminUserDayoffItemData";

interface AdminUserDayoffItemProps {
    adminUserDayoffItemData: AdminUserDayoffItemData;
}

const AdminUserDayyoffItem = (props : AdminUserDayoffItemProps) => {
    return (
        <DayoffItemContainer>
            <DayoffItemLeftContainer>
                <span>{props.adminUserDayoffItemData.year}년 {props.adminUserDayoffItemData.month}월 {props.adminUserDayoffItemData.date}일 {props.adminUserDayoffItemData.day}요일</span>
                <span>{props.adminUserDayoffItemData.dayoffType}</span>
            </DayoffItemLeftContainer>
            <DayoffItemRightContainer>
                <DayoffStatus status={props.adminUserDayoffItemData.dayoffStatus}>
                    {DayoffCodeLabel[props.adminUserDayoffItemData.dayoffStatus as keyof typeof DayoffCodeLabel]}
                </DayoffStatus>
            </DayoffItemRightContainer>
        </DayoffItemContainer>
    );
}
export default AdminUserDayyoffItem;