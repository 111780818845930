import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class AdminUserDayoffListRequest extends BaseRequest {
    private readonly URL_ADMIN_BASE = `${BaseService.API_URL}/admin`;
    private URL_ADMIN_USER_DAYOFF_LIST_URL = this.URL_ADMIN_BASE+"/userDayoffList";

    getURL(): string {
        return this.URL_ADMIN_USER_DAYOFF_LIST_URL;
    }
}