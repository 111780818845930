export default class DayoffItemData {

    private _dayoffType!: string;   
    private _dayoffStatus!: string;
    private _year!: number;
    private _month!: number;
    private _date!: number;
    private _day!: string;

    public get dayoffType(): string {
        return this._dayoffType;
    }
    public set dayoffType(value: string) {
        this._dayoffType = value;
    }
    public get dayoffStatus(): string {
        return this._dayoffStatus;
    }
    public set dayoffStatus(value: string) {
        this._dayoffStatus = value;
    }   
    public get year(): number {
        return this._year;
    }
    public set year(value: number) {
        this._year = value;
    }
    public get month(): number {
        return this._month;
    }
    public set month(value: number) {
        this._month = value;
    }
    public get date(): number {
        return this._date;
    }
    public set date(value: number) {
        this._date = value;
    }
    public get day(): string {
        return this._day;
    }
    public set day(value: string) {
        this._day = value;
    }
    
}