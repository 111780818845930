import { useEffect, useState } from "react";
import AdminDayoffItem from "./AdminDayoffItem/AdminDayoffItem";
import AdminDayoffListData from "./AdminDayoffListData";
import AdminDayoffListReqData from "data/network/req/admin/AdminDayoffListReqData";
import AdminDayoffListRequest from "network/Admin/AdminDayoffListRequest";
import AdminDayoffListResData from "data/network/res/admin/AdminDayoffListResData";
import { resAdminDayoffListToAdminDayoffListData } from "converter/AdminConverter";
import Calendar from "ui/components/Calendar/Calendar";
import { useRecoilValue } from "recoil";
import { dateState } from "atoms/date";
import { format } from "date-fns";
import AdminDayoffApprovalRequest from "network/Admin/AdminDayoffApprovalRequest";
import AdminDayoffApprovalReqData from "data/network/req/admin/AdminDayoffApprovalReqData";
import { responseCode } from "code/Enumerator";
import { useAlertModal } from "hooks/useModal";
import { DayoffDateGroupTitle, DayoffItemWrapper, DayoffListContainer, PageContainer } from "./AdminDayoffList.styles";

let adminDayoffListData: AdminDayoffListData = new AdminDayoffListData();
const AdminDayoffList = () => {
    const [viewModel, setViewModel] = useState({adminDayoffListData});
    const adminDayoffListRequest : AdminDayoffListRequest = new AdminDayoffListRequest();
    const adminDayoffApprovalRequest : AdminDayoffApprovalRequest = new AdminDayoffApprovalRequest();
    const currentDate = useRecoilValue(dateState);
    const { openAlertModal } = useAlertModal();

    useEffect(() => {
        init();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        init();
        // eslint-disable-next-line
    }, [currentDate]);

    const init = async() => {
        await reqAdminDayoffList();
    }

    const reqAdminDayoffList = async() => {
        const adminDayoffListReqData : AdminDayoffListReqData = new AdminDayoffListReqData();
        const year = format(currentDate, 'yyyy');
        const month = format(currentDate, 'MM');
        adminDayoffListReqData.year = parseInt(year, 10);
        adminDayoffListReqData.month = parseInt(month, 10);
        const adminDayoffListResData = (await adminDayoffListRequest.send(adminDayoffListReqData)) as AdminDayoffListResData;
        adminDayoffListData = resAdminDayoffListToAdminDayoffListData(adminDayoffListResData);
        adminDayoffListData.adminDayoffList.sort((a, b) => {
            const aDate = new Date(`${a.year}-${a.month}-${a.day}`);
            const bDate = new Date(`${b.year}-${b.month}-${b.day}`);
            return bDate.getTime() - aDate.getTime();
        })
        adminDayoffListData.adminDayoffDateList = adminDayoffListData.adminDayoffList.map((item) => `${item.year}년 ${item.month}월 ${item.date}일`);
        adminDayoffListData.adminDayoffDateList.forEach((item) => {
            adminDayoffListData.adminDayoffGroupList.push([]);
        });
        adminDayoffListData.adminDayoffList.forEach((item) => {
            const index = adminDayoffListData.adminDayoffDateList.findIndex((date) => date === `${item.year}년 ${item.month}월 ${item.date}일`);
            adminDayoffListData.adminDayoffGroupList[index].push(item);
        });
        setViewModel({adminDayoffListData});
    }

    const reqApproveDayoff = async(dayoffId : string, dayoffStatus : string) => {
        const adminDayoffApprovalReqData : AdminDayoffApprovalReqData = new AdminDayoffApprovalReqData();
        adminDayoffApprovalReqData.dayoffId = dayoffId;
        adminDayoffApprovalReqData.dayoffStatus = dayoffStatus;
        console.log(adminDayoffApprovalReqData);
        const response : any = (await adminDayoffApprovalRequest.send(adminDayoffApprovalReqData));
        if (response.responseCode !== responseCode.OK) {
            openAlertModal({text:'저장에 실패하였습니다.'});
            return;
        }
        openAlertModal({text:'저장되었습니다.'});
        adminDayoffListData.adminDayoffList = adminDayoffListData.adminDayoffList.filter((item) => item.dayoffStatus === 'WAIT');
        setViewModel({adminDayoffListData});
    }

    return (
        <PageContainer>
            <Calendar />
            <DayoffListContainer>
            {viewModel.adminDayoffListData.adminDayoffDateList.length > 0 ? (
                adminDayoffListData.adminDayoffDateList.map((item, index) => (
                    <DayoffItemWrapper key={index}>
                        <DayoffDateGroupTitle>{item.substring(6)}</DayoffDateGroupTitle>
                        {adminDayoffListData.adminDayoffGroupList[index].map((item, index) => (
                        <AdminDayoffItem 
                            key={index} 
                            adminDayoffItemData={item}
                            reqApproveDayoff={reqApproveDayoff}
                        />
                        ))}
                    </DayoffItemWrapper>
                ))
            ):(
                <DayoffItemWrapper>휴가 신청이 없습니다.</DayoffItemWrapper>
            )}
            </DayoffListContainer>
        </PageContainer>
    );
}
export default AdminDayoffList;