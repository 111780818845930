import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import { RecoilRoot } from 'recoil';
import Modal from 'react-modal';
import Modals from 'ui/components/Modals/Modals';

Modal.setAppElement('#root');
function App() {
  return (
    <RecoilRoot>
       <BrowserRouter>
        <AppRoutes />
        <Modals />
      </BrowserRouter>
    </RecoilRoot>
  
  );
}

export default App;
