import DashBoardData from "./DashBoardData";
import Calendar from "../../components/Calendar/Calendar";
import { useRecoilValue } from "recoil";
import { dateState } from "atoms/date";
import { useDayoffRegistrationModal } from "hooks/useModal";
import { DayoffRegistrationModalProps } from "ui/components/Modals/DayoffRegistrationModal/DayoffRegistrationModal";
import { DashBoardContainer, DayoffAddButton, DayoffContainer, DayoffItem, DayoffTopContainer } from "./DashBoard.styles";
import { useEffect, useState } from "react";
import TodayDayoffListRequest from "network/DashBoard/TodayDayoffListRequest";
import TodayDayoffListReqData from "data/network/req/dashboard/TodayDayoffListReqData";
import TodayDayoffListResData from "data/network/res/dashboard/TodayDayoffListResData";
import { format } from "date-fns";
import { stringToDayOfWeekCode } from "code/DashBoard/DateConverter";

let dashBoardData : DashBoardData = new DashBoardData();
const DashBoard = () => {
    const [viewModel, setViewModel] = useState({dashBoardData});
    const todayDayoffListRequest : TodayDayoffListRequest = new TodayDayoffListRequest();
    const currentDate = useRecoilValue(dateState);
    const userRole = localStorage.getItem("authorized");
    const {openDayoffRegistrationModal} = useDayoffRegistrationModal();

    useEffect(() => {
        init();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        init();
        // eslint-disable-next-line
    }, [currentDate]);

    const init = async() => {
        await reqDayoffList();
    }

    const reqDayoffList = async() => {
        const todayDayoffListReqData : TodayDayoffListReqData = new TodayDayoffListReqData();       
        const year = format(currentDate, 'yyyy');
        const month = format(currentDate, 'MM');
        const date = format(currentDate, 'dd');
        todayDayoffListReqData.year = parseInt(year, 10);
        todayDayoffListReqData.month = parseInt(month, 10);
        todayDayoffListReqData.date = parseInt(date, 10);
        const todayDayoffListResData = (await todayDayoffListRequest.send(todayDayoffListReqData)) as TodayDayoffListResData;
        dashBoardData.todayDayoffList = [];
        todayDayoffListResData.todayDayoffList?.forEach((item) => {
            dashBoardData.todayDayoffList.push(item.userName);
        });
        setViewModel({dashBoardData});
    }
    
    const handleDayoffRegistrationBtnClick = () => {
        const modalProps: Omit<DayoffRegistrationModalProps, "onClose"> = {
            currentDate: currentDate
        };
        openDayoffRegistrationModal(modalProps);
    }

    const getMonth = () => {
        return format(currentDate, 'MM').startsWith('0') ? format(currentDate, 'MM').substring(1) : format(currentDate, 'MM');
    }

    const getDate = () => {
        return format(currentDate, 'dd').startsWith('0') ? format(currentDate, 'dd').substring(1) : format(currentDate, 'dd');
    }

    return (
        <DashBoardContainer>
            <Calendar />
            <DayoffContainer>
                <DayoffTopContainer>
                <p>{getMonth()}월 {getDate()}일, {stringToDayOfWeekCode[format(currentDate, 'EEEE')]}</p>
                {userRole === 'USER' && (
                    <DayoffAddButton onClick={handleDayoffRegistrationBtnClick}>연차 신청</DayoffAddButton>
                )}         
                </DayoffTopContainer>

                {viewModel.dashBoardData.todayDayoffList.length > 0 ? (
                    viewModel.dashBoardData.todayDayoffList.map((item) => (
                        <DayoffItem key={item}>{item}</DayoffItem>
                    ))
                ) : (
                    <DayoffItem>연차 사용자가 없습니다.</DayoffItem>
                )}
                <div>
                </div>
            </DayoffContainer>
            
        </DashBoardContainer>
    );
}
export default DashBoard;