import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class LoginRequest extends BaseRequest{
    private readonly URL_USER_BASE = `${BaseService.API_URL}/user`;
    private URL_USER_LOGIN_URL = this.URL_USER_BASE+"/login";

    getURL(): string {
        return this.URL_USER_LOGIN_URL;
    }
}