export enum LoginCode {
    SUCCESS = "SUCCESS",
    EMAIL_NOT_EXIST = "EMAIL_NOT_EXIST",
    PASSWORD_NOT_CORRECT = "PASSWORD_NOT_CORRECT",
    LOGIN_LOCKED = "LOGIN_LOCKED",
    EMAIL_BLANK = "EMAIL_BLANK",
    PASSWORD_BLANK = "PASSWORD_BLANK",
    WAITING_APPROVAL = "WAITING_APPROVAL",
    WITHDRAWN_USER = "WITHDRAWN_USER",
    REJECTED_USER = "REJECTED_USER",
    ERROR = "ERROR",
    DATA_VALIDATED = "DATA_VALIDATED",
    LOGOUT = "LOGOUT"
};

export enum LoginCodeLabel {
    SUCCESS = "로그인 성공",
    EMAIL_NOT_EXIST = "이메일이 존재하지 않습니다.",
    PASSWORD_NOT_CORRECT = "비밀번호가 잘못되었습니다.",
    LOGIN_LOCKED = "로그인 시도 가능 횟수가 초과되었습니다. 10분 후에 다시 시도해주세요.",
    EMAIL_BLANK = "이메일을 입력해주십시오.",
    PASSWORD_BLANK = "비밀번호를 입력해주십시오.",
    WAITING_APPROVAL = "현재 가입 승인 대기중입니다. 관리자를 통해 승인을 요청하세요.",
    WITHDRAWN_USER = "퇴사 처리되어 로그인할 수 없습니다.",
    REJECTED_USER = "가입 거절되어 로그인할 수 없습니다.",
    ERROR = "로그인 중 오류가 발생했습니다.",
    DATA_VALIDATED = "데이터 검증 완료",
    LOGOUT = "로그아웃 되었습니다."
};