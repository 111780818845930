import styled from '@emotion/styled';

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
`
export const PageTitle = styled.div`
    font-size: 24px;
    font-weight: bold;
    margin-top: 20%;
    padding-bottom: 10px;
    margin-bottom: 10px;
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
`;

export const AdminUserListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
`