import { useEffect, useState, useRef } from "react";
import { CancelButton, Input, InputContainer, InputDescriber, InputIconContainer, PageContainer, PageTitle, SignUpButton } from "./SignUp.styles";
import SignUpData from "./SignUpData";
import SignUpRequest from "network/User/SignUpRequest";
import { signUpDataToSignUpReqData } from "converter/UserConverter";
import { SignUpCode, SignUpCodeLabel } from "code/User/SignUpCode";
import CheckEmailRequest from "network/User/CheckEmailRequest";
import CheckEmailReqData from "data/network/req/user/CheckEmailReqData";
import { HiEye, HiOutlineEyeOff, HiCheck } from "react-icons/hi";
import DatePicker from "react-multi-date-picker";
import DatePickerLocale from "common/utils/DatePickerLocale";
import { FaCalendarAlt } from "react-icons/fa";
import "./SignUp.css";
import "react-multi-date-picker/styles/layouts/mobile.css"
import UserValidator from "validator/User/UserValidator";
import { useAlertModal } from "hooks/useModal";


const signUpData: SignUpData = new SignUpData();
const SignUp = () => {
    const [viewModel, setViewModel] = useState({signUpData});
    const emailInputRef = useRef<HTMLInputElement>(null);
    const nameInputRef = useRef<HTMLInputElement>(null);
    const passwordInputRef = useRef<HTMLInputElement>(null);
    const passwordConfirmInputRef = useRef<HTMLInputElement>(null);
    const {openAlertModal} = useAlertModal();

    useEffect(() => {
        init();
        // eslint-disable-next-line
    }, []);
    
    const init = () => {
        setViewModel({signUpData: signUpData});
    }

    const reqSignUp = async () => {
        const signUpValidation = UserValidator.signUpValidate(signUpData);
        if (signUpValidation !== SignUpCodeLabel.DATA_VALIDATED) {
            openAlertModal({text: signUpValidation});
            return;
        }
        const signUpRequest = new SignUpRequest();
        const signUpReqData = signUpDataToSignUpReqData(viewModel.signUpData);
        const response = await signUpRequest.send(signUpReqData);
        handleSignUpResult(response);
    }

    const reqCheckEmailDuplicated = async () => {
        if (!UserValidator.emailFormatValidate(viewModel.signUpData.userEmail)) {
            return;
        }
        const checkEmailRequest = new CheckEmailRequest();
        const checkEmailReqData = new CheckEmailReqData();
        checkEmailReqData.userEmail = viewModel.signUpData.userEmail;
        const response = await checkEmailRequest.send(checkEmailReqData);
        handleCheckEmailResult(response);
    }

    const changeUserEmail = (value: string) => {
        viewModel.signUpData.userEmail = value;
        viewModel.signUpData.emailDuplicationChecked = false;
        setViewModel({signUpData: viewModel.signUpData});
    }

    const changeUserName = (value: string) => {
        viewModel.signUpData.userName = value;
        setViewModel({signUpData: viewModel.signUpData});
    }

    const changeUserPassword = (value: string) => {
        viewModel.signUpData.userPassword = value;
        setViewModel({signUpData: viewModel.signUpData});
    }

    const changeUserPasswordConfirm = (value: string) => {
        viewModel.signUpData.userPasswordConfirm = value;
        setViewModel({signUpData: viewModel.signUpData});
    }

    const handleUserEmailInputFocus = (focused: boolean) => {
        viewModel.signUpData.emailInputFocused = focused;
        setViewModel({signUpData: viewModel.signUpData});
    }

    const handleUserNameInputFocus = (focused: boolean) => {
        viewModel.signUpData.userNameInputFocused = focused;
        setViewModel({signUpData: viewModel.signUpData});
    }

    const handlePasswordInputFocus = (focused: boolean) => {
        viewModel.signUpData.passwordInputFocused = focused;
        setViewModel({signUpData: viewModel.signUpData});
    }

    const handlePasswordConfirmInputFocus = (focused: boolean) => {
        viewModel.signUpData.passwordConfirmInputFocused = focused;
        setViewModel({signUpData: viewModel.signUpData});
    }

    const handleInputFocus = (ref: React.RefObject<HTMLInputElement>) => {
        if (ref.current) {
            ref.current.focus();
        }
    }

    const handleCheckEmailDuplicated = (e: any) => {
        reqCheckEmailDuplicated();
        e.preventDefault();
    }

    const handlePasswordVisible = () => {
        viewModel.signUpData.passwordVisible = !viewModel.signUpData.passwordVisible;
        passwordInputRef.current?.setAttribute('type', viewModel.signUpData.passwordVisible ? 'text' : 'password');
        setViewModel({signUpData: viewModel.signUpData});
    };

    const handlePasswordConfirmVisible = () => {
        viewModel.signUpData.passwordConfirmVisible = !viewModel.signUpData.passwordConfirmVisible;
        passwordConfirmInputRef.current?.setAttribute('type', viewModel.signUpData.passwordConfirmVisible ? 'text' : 'password');
        setViewModel({signUpData: viewModel.signUpData});
    }

    const handleStartDateChange = (value: any) => {
        viewModel.signUpData.userStartDate = value.format('YYYY-MM-DD');
        setViewModel({signUpData: viewModel.signUpData});
    }

    const handleSignUp = async () => {
        reqSignUp();
        
    }

    const cancelSignUp = () => {
        window.history.back();
    }

    const handleSignUpResult = (response: any) => {
        switch (response.responseMessage) {
            case SignUpCode.SUCCESS:
                openAlertModal({text: SignUpCode.SUCCESS});
                window.location.href = '/login';
                break;
            case SignUpCode.ERROR:
                openAlertModal({text: SignUpCode.ERROR});
                break;
            default:
                openAlertModal({text: SignUpCode.ERROR});
                break;
        }
    }

    const handleCheckEmailResult = (response: any) => {
        console.log(response);
        switch (response.responseMessage) {
            case SignUpCode.EMAIL_AVAILABLE:
                openAlertModal({text: SignUpCodeLabel.EMAIL_AVAILABLE});
                viewModel.signUpData.emailDuplicationChecked = true;
                setViewModel({signUpData: viewModel.signUpData});
                break;
            case SignUpCode.EMAIL_IN_USED:
                openAlertModal({text: SignUpCodeLabel.EMAIL_IN_USED});
                viewModel.signUpData.emailDuplicationChecked = false;
                setViewModel({signUpData: viewModel.signUpData});
                break;
            default:
                openAlertModal({text: SignUpCode.ERROR});
                viewModel.signUpData.emailDuplicationChecked = false;
                setViewModel({signUpData: viewModel.signUpData});
                break;
        }
    }

    return (
        <PageContainer>
            <PageTitle>회원가입</PageTitle>
            <InputContainer onClick={() => handleInputFocus(emailInputRef)} focused={viewModel.signUpData.emailInputFocused} length={viewModel.signUpData.userEmail.length}>
                <InputDescriber focused={viewModel.signUpData.emailInputFocused} length={viewModel.signUpData.userEmail.length}>이메일</InputDescriber>
                <Input
                    focused={viewModel.signUpData.emailInputFocused}
                    ref={emailInputRef}
                    onFocus={() => handleUserEmailInputFocus(true)}
                    onBlur={() => handleUserEmailInputFocus(false)}
                    length={viewModel.signUpData.userEmail.length}
                    onChange={(e) => changeUserEmail(e.target.value)}
                />
                <InputIconContainer onClick={handleCheckEmailDuplicated}>
                    <HiCheck color={viewModel.signUpData.emailDuplicationChecked ? 'green' : 'gray'} />
                </InputIconContainer>
            </InputContainer>
            <InputContainer onClick={() => handleInputFocus(nameInputRef)} focused={viewModel.signUpData.userNameInputFocused} length={viewModel.signUpData.userName.length}>
                <InputDescriber focused={viewModel.signUpData.userNameInputFocused} length={viewModel.signUpData.userName.length}>이름</InputDescriber>
                <Input
                    ref={nameInputRef}
                    focused={viewModel.signUpData.userNameInputFocused}
                    onFocus={() => handleUserNameInputFocus(true)}
                    onBlur={() => handleUserNameInputFocus(false)}
                    length={viewModel.signUpData.userName.length}
                    onChange={(e) => changeUserName(e.target.value)}
                />
            </InputContainer>
            <InputContainer onClick={() => handleInputFocus(passwordInputRef)} focused={viewModel.signUpData.passwordInputFocused} length={viewModel.signUpData.userPassword.length}>
                <InputDescriber focused={viewModel.signUpData.passwordInputFocused} length={viewModel.signUpData.userPassword.length}>비밀번호</InputDescriber>
                <Input
                    ref={passwordInputRef}
                    type="password"
                    focused={viewModel.signUpData.passwordInputFocused}
                    onFocus={() => handlePasswordInputFocus(true)}
                    onBlur={() => handlePasswordInputFocus(false)}
                    length={viewModel.signUpData.userPassword.length}
                    onChange={(e) => changeUserPassword(e.target.value)}
                />
                <InputIconContainer onClick={handlePasswordVisible}>
                    {
                        viewModel.signUpData.passwordVisible ? <HiEye /> : <HiOutlineEyeOff />
                    }
                </InputIconContainer>
            </InputContainer>
            <InputContainer onClick={() => handleInputFocus(passwordConfirmInputRef)} focused={viewModel.signUpData.passwordConfirmInputFocused} length={viewModel.signUpData.userPasswordConfirm.length}>
                <InputDescriber focused={viewModel.signUpData.passwordConfirmInputFocused} length={viewModel.signUpData.userPasswordConfirm.length}>비밀번호 확인</InputDescriber>
                <Input
                    ref={passwordConfirmInputRef}
                    type="password"
                    focused={viewModel.signUpData.passwordConfirmInputFocused}
                    onFocus={() => handlePasswordConfirmInputFocus(true)}
                    onBlur={() => handlePasswordConfirmInputFocus(false)}
                    length={viewModel.signUpData.userPasswordConfirm.length}
                    onChange={(e) => changeUserPasswordConfirm(e.target.value)}
                />
                <InputIconContainer onClick={handlePasswordConfirmVisible}>
                    {
                        viewModel.signUpData.passwordConfirmVisible ? <HiEye /> : <HiOutlineEyeOff />
                    }
                </InputIconContainer>
            </InputContainer>
            <InputContainer>
                <DatePicker
                    inputClass="custom-date-picker"
                    value={viewModel.signUpData.userStartDate}
                    placeholder="입사일"
                    onChange={handleStartDateChange}
                    locale={DatePickerLocale}
                    format={"YYYY-MM-DD"}
                    formatYear={(year) => `${year}년`}
                    formatMonth={(month) => `${month}`}
                    headerOrder={["YEAR_MONTH", "LEFT_BUTTON", "RIGHT_BUTTON"]}
                    monthYearSeparator=" "
                    className="rmdp-mobile"
                />
                <InputIconContainer>
                    <FaCalendarAlt />
                </InputIconContainer>
            </InputContainer>
            <SignUpButton onClick={handleSignUp}>회원가입</SignUpButton>
            <CancelButton onClick={cancelSignUp}>취소</CancelButton>
        </PageContainer>
    );
}
export default SignUp;