import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class DayoffListRequest extends BaseRequest{
    private readonly URL_DAYOFF_BASE = `${BaseService.API_URL}/dayoff`;
    private URL_DAYOFF_LIST_URL = this.URL_DAYOFF_BASE+"/list";

    getURL(): string {
        return this.URL_DAYOFF_LIST_URL;
    }
}