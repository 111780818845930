import styled from '@emotion/styled';

export const PageContainer = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    padding-top: 10%;
    p {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        line-height: 21px; /* 150% */
        white-space: pre-wrap;
    }
`;

export const DayoffListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
`;

export const DayoffItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px 15px;
    border-radius: 8px;
    box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.4);
`;

export const DayoffDateGroupTitle = styled.div`
    font-size: 18px;
    font-weight: 600;
`; 
