import { useEffect, useState } from "react";
import AdminUserListData from "./AdminUserListData";
import AdminUserListRequest from "network/Admin/AdminUserListRequest";
import AdminUserListReqData from "data/network/req/admin/AdminUserListReqData";
import AdminUserListResData from "data/network/res/admin/AdminUserListResData";
import { resAdminUserListToAdminUserListData } from "converter/AdminConverter";
import AdminUserItem from "./AdminUserItem/AdminUserItem";
import { UserStatusCode } from "code/User/UserStatusCode";
import AdminUserApprovalReqData from "data/network/req/admin/AdminUserApprovalReqData";
import AdminUserItemData from "./AdminUserItem/AdminUserItemData";
import AdminUserApprovalRequest from "network/Admin/AdminUserApprovalRequest";
import { AdminUserListContainer, PageContainer, PageTitle } from "./AdminUserList.styles";

let adminUserListData : AdminUserListData = new AdminUserListData();
const AdminUserList = () => {
    const [viewModel, setViewModel] = useState({adminUserListData});
    const adminUserListRequest : AdminUserListRequest = new AdminUserListRequest();

    useEffect(() => {
        init();
        // eslint-disable-next-line
    }, []);

    const init = async() => {
        await reqAdminUserList();
    }

    const reqAdminUserList = async() => {
        const adminUserListReqData = new AdminUserListReqData();
        const adminUserListResData = (await adminUserListRequest.send(adminUserListReqData)) as AdminUserListResData;
        adminUserListData = resAdminUserListToAdminUserListData(adminUserListResData);
        setViewModel({adminUserListData});
    }

    const reqUserSignUpApproval = async(status: UserStatusCode, user: AdminUserItemData) => {
        const adminUserApprovalReqData = new AdminUserApprovalReqData();
        adminUserApprovalReqData.userEmail = user.userEmail;
        adminUserApprovalReqData.userStartDate = user.userStartDate;
        adminUserApprovalReqData.userStatus = status;
        const adminUserSignUpApprovalReuqest = new AdminUserApprovalRequest();
        await adminUserSignUpApprovalReuqest.send(adminUserApprovalReqData);
        await init();
    }


    const handleApprovalButtonClick = (user: AdminUserItemData) => {
        if (window.confirm(`${user.userName}님의 가입을 승인하시겠습니까?`)) {
            reqUserSignUpApproval(UserStatusCode.WORK, user);
        }
    }

    const handleRejectButtonClick = (user: AdminUserItemData) => {
        if (window.confirm(`${user.userName}님의 가입을 거절하시겠습니까?`)) {
            reqUserSignUpApproval(UserStatusCode.REJECT, user);
        }
    }

    return (
        <PageContainer>
            <PageTitle>사용자 관리</PageTitle>
            <AdminUserListContainer>
                {viewModel.adminUserListData.adminUserList.length > 0 ? (
                    adminUserListData.adminUserList.map((item) => (
                        <AdminUserItem 
                            key={item.userName}
                            adminUserItemData={item}
                            handleApprovalButtonClick={() => handleApprovalButtonClick(item)}
                            handleRejectButtonClick={() => handleRejectButtonClick(item)}
                        />
                    ))
                ):(
                    <p>사용자가 없습니다.</p>
                )}
            </AdminUserListContainer>
        </PageContainer>
    );
}
export default AdminUserList;