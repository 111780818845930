export default class SignUpData {
    private _userEmail: string = "";
    private _userName: string = "";
    private _userPassword: string = "";
    private _userPasswordConfirm: string = ""; 
    private _userStartDate: string = "";
    private _emailInputFocused: boolean = false;
    private _userNameInputFocused: boolean = false;
    private _passwordInputFocused: boolean = false;
    private _passwordConfirmInputFocused: boolean = false;
    private _passwordVisible: boolean = false;
    private _passwordConfirmVisible: boolean = false;
    private _emailDuplicationChecked: boolean = false;

    public get userEmail(): string {
        return this._userEmail;
    }
    public set userEmail(value: string) {
        this._userEmail = value;
    }
    public get userPassword(): string {
        return this._userPassword;
    }
    public set userPassword(value: string) {
        this._userPassword = value;
    }
    public get emailInputFocused(): boolean {
        return this._emailInputFocused;
    }
    public set emailInputFocused(value: boolean) {
        this._emailInputFocused = value;
    }
    public get passwordInputFocused(): boolean {
        return this._passwordInputFocused;
    }
    public set passwordInputFocused(value: boolean) {
        this._passwordInputFocused = value;
    }
    public get passwordVisible(): boolean {
        return this._passwordVisible;
    }
    public set passwordVisible(value: boolean) {
        this._passwordVisible = value;
    }
    public get passwordConfirmVisible(): boolean {
        return this._passwordConfirmVisible;
    }
    public set passwordConfirmVisible(value: boolean) {
        this._passwordConfirmVisible = value;
    }
    public get userName(): string {
        return this._userName;
    }
    public set userName(value: string) {
        this._userName = value;
    }
    public get userPasswordConfirm(): string {
        return this._userPasswordConfirm;
    }
    public set userPasswordConfirm(value: string) {
        this._userPasswordConfirm = value;
    }
    public get userStartDate(): string {
        return this._userStartDate;
    }
    public set userStartDate(value: string) {
        this._userStartDate = value;
    }
    public get userNameInputFocused(): boolean {
        return this._userNameInputFocused;
    }
    public set userNameInputFocused(value: boolean) {
        this._userNameInputFocused = value;
    }
    public get passwordConfirmInputFocused(): boolean {
        return this._passwordConfirmInputFocused;
    }
    public set passwordConfirmInputFocused(value: boolean) {
        this._passwordConfirmInputFocused = value;
    }
    public get emailDuplicationChecked(): boolean {
        return this._emailDuplicationChecked;
    }
    public set emailDuplicationChecked(value: boolean) {
        this._emailDuplicationChecked = value;
    }
}