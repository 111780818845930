import axios, { AxiosResponse } from "axios";
import BaseReqData from "data/network/req/BaseReqData";
import BaseService from "./BaseService";
import BaseResData from "data/network/res/BaseResData";
import UserData from "data/UserData";
import { responseCode } from "code/Enumerator";
export abstract class BaseRequest{
  API = {
    client: axios.create({
      baseURL: `${BaseService.API_URL}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }),
    async post(url: string, data?: BaseReqData){
      try {
        const response: AxiosResponse = await this.client.post(url, data, {headers: {Authorization: `Bearer ${sessionStorage.accessToken || ""}`,}});
        return response.data;
      } catch (e: any) {
        if (e.response.status === 401) {
          try {
            console.log("enter error");
            const newRes = await this.refreshToken(url, data);
            return newRes;
          } catch (e: any) {
            if (e.message === "Request failed with status code 401") {
              window.location.href = "/login";
              if (localStorage.getItem("authorized")) {
                localStorage.removeItem("authorized");
              }
            }
          }
        }
      }
    },
    async get(url: string, data?: BaseReqData){
      const response: AxiosResponse = await this.client.get(url, {params: data});
      return response.data;
    },
    async refreshToken(url: string, data?: BaseReqData){
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        try {
          const refreshRes: AxiosResponse = await this.client.post(
            "/user/refresh-token",
            {},
            {headers: {Authorization: `Bearer ${refreshToken}`}}
          );
          if (refreshRes.data.responseCode === responseCode.UNAUTHORIZED) {
            window.location.href = "/login";
            if (localStorage.getItem("authorized")) {
              localStorage.clear();
              sessionStorage.clear();
            }
            return new BaseResData();
          }
          sessionStorage.setItem("accessToken", refreshRes.data.data.accessToken);
          localStorage.setItem("refreshToken", refreshRes.data.data.refreshToken);
          const response: AxiosResponse = await this.client.post(url, data, {headers: {Authorization: `Bearer ${refreshRes.data.data.accessToken}`}});
          console.log(response);
          return response.data;
        } catch (e: any) {
          console.log(e);
          if (e.message === "Request failed with status code 401") {
            window.location.href = "/login";
            if (localStorage.getItem("authorized")) {
              localStorage.clear();
              sessionStorage.clear();
            }
            return new BaseResData();
          }
        }
      }
    }
  };
  private static readonly REQ_QUEUE: Map<string, BaseReqData> = new Map();

  abstract getURL():string;
  async send(data : BaseReqData):Promise<BaseResData>{
    return this.request(data, "post");
  };
  async get(data : BaseReqData):Promise<BaseResData>{
    return this.request(data, "get");
  }
  async request(data : BaseReqData, method : string):Promise<BaseResData>{
    const context =this;
    const urlkey = context.getURL()+ JSON.stringify(data);
    if (BaseRequest.REQ_QUEUE.has(urlkey)) {
      return new BaseResData();
    }
    BaseRequest.REQ_QUEUE.set(urlkey, data!);
    const token = UserData.instance.accessToken;
    if(token !== null || token !== ""){
      context.API.client.defaults.headers.common['Authorization'] = 'Bearer '+token;
    }
    const response = await context.API[method as keyof typeof context.API](context.getURL(), data);
    setTimeout( () => {
      BaseRequest.REQ_QUEUE.delete(urlkey);
    }, 1000);
    const resData: BaseResData = response.data || new BaseResData();
    if(response.responseCode === responseCode.UNAUTHORIZED){
      localStorage.removeItem('USER_DATA');
      window.location.href = '/login';
    }
    resData.responseCode=response.responseCode;
    resData.responseMessage=response.responseMessage;
    return resData;
  }
  getToken() {
    if (sessionStorage.getItem("accessToken")) {
      return `Bearer ${sessionStorage.getItem("accessToken")}`;
    }
    return "";
  }

}