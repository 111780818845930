export enum DayOfWeekCode{
    MONDAY = "Monday",
    TUESDAY = "Tuesday",
    WEDNESDAY = "Wednesday",
    THURSDAY = "Thursday",
    FRIDAY = "Friday",
    SATURDAY = "Saturday",
    SUNDAY = "Sunday"    
}

export enum DayOfWeekCodeLabel {
    MONDAY = "월",
    TUESDAY = "화",
    WEDNESDAY = "수",
    THURSDAY = "목",
    FRIDAY = "금",
    SATURDAY = "토",
    SUNDAY = "일"
}

export const stringToDayOfWeekCode: { [key: string]: string } = {
    "Monday": "월",
    "Tuesday": "화",
    "Wednesday": "수",
    "Thursday": "목",
    "Friday": "금",
    "Saturday": "토",
    "Sunday": "일",
};