export enum UserStatusCode {
    WAIT = "WAIT",
    WORK = "WORK",
    WITHDRAW = "WITHDRAW",
    REST = "REST",
    REJECT = "REJECT"
};

export enum UserStatusCodeLabel {
    WAIT = "승인 대기",
    WORK = "재직",
    WITHDRAW = "퇴사",
    REST = "휴직",
    REJECT = "거절"
};

export enum UserStatusCodeColor {
    WAIT = "green",
    WORK = "blue",
    WITHDRAW = "gray",
    REST = "black",
    REJECT = "red"
};

export const stringToUserStatusCode: { [key: string]: string } = {
    "WAIT": "승인 대기",
    "WORK": "재직",
    "WITHDRAW": "퇴사",
    "REST": "휴직",
    "REJECT": "거절"
};

export const UserStatusOptions = [
    {value: UserStatusCode.WAIT, label: UserStatusCodeLabel.WAIT},
    {value: UserStatusCode.WORK, label: UserStatusCodeLabel.WORK},
    {value: UserStatusCode.WITHDRAW, label: UserStatusCodeLabel.WITHDRAW},
    {value: UserStatusCode.REST, label: UserStatusCodeLabel.REST},
    {value: UserStatusCode.REJECT, label: UserStatusCodeLabel.REJECT}
];
