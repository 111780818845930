import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class UserDetailRequest extends BaseRequest{
    private readonly URL_USER_BASE = `${BaseService.API_URL}/user`;
    private URL_USER_INFO_URL = this.URL_USER_BASE+"/info";

    getURL(): string {
        return this.URL_USER_INFO_URL;
    }
}