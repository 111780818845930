import { BaseRequest } from "network/BaseRequest";
import BaseService from "network/BaseService";

export default class AdminUserApprovalRequest extends BaseRequest{
    private readonly URL_ADMIN_BASE = `${BaseService.API_URL}/admin`;
    private URL_ADMIN_USER_APPROVAL_URL = this.URL_ADMIN_BASE+"/userApproval";

    getURL(): string {
        return this.URL_ADMIN_USER_APPROVAL_URL;
    }
}