export enum UserPositionCode {
    "차장" = "차장",
    "과장" = "과장",
    "대리" = "대리",
    "사원" = "사원"
};

export const UserPositionOptions = [
    {value: UserPositionCode.차장, label: UserPositionCode.차장},
    {value: UserPositionCode.과장, label: UserPositionCode.과장},
    {value: UserPositionCode.대리, label: UserPositionCode.대리},
    {value: UserPositionCode.사원, label: UserPositionCode.사원}
];